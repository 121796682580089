@if (customerManagementService.customer(); as customer) {
  <div class="container px-2 mx-auto">
    <axpo-typography variant="title-xl">
      {{ customer.companyName }}
    </axpo-typography>

    <div class="flex w-full justify-between items-start">
      <div class="my-5">
        <div class="flex">
          <axpo-typography class="text-text-secondary w-32">
            {{ 'customer.CustomerName' | transloco }}:
          </axpo-typography>
          <axpo-typography>{{ customer.companyName }}</axpo-typography>
        </div>
        <div class="flex">
          <axpo-typography class="text-text-secondary w-32">
            {{ 'customer.ContactPerson' | transloco }}:
          </axpo-typography>
          <axpo-typography>{{ customer.contactPerson }}</axpo-typography>
        </div>
        <div class="flex">
          <axpo-typography class="text-text-secondary w-32">
            {{ 'customer.ContactPhone' | transloco }}:
          </axpo-typography>
          <axpo-typography>{{ customer.contactPhone }}</axpo-typography>
        </div>
        <div class="flex">
          <axpo-typography class="text-text-secondary w-32">
            {{ 'customer.ContactEmail' | transloco }}:
          </axpo-typography>
          <axpo-typography>{{ customer.contactEmail }}</axpo-typography>
        </div>
        <div class="flex">
          <axpo-typography class="text-text-secondary w-32">
            {{ 'customer.Status' | transloco }}:
          </axpo-typography>
          <axpo-typography>
            {{ (customer.isActive ? 'customer.Active' : 'customer.Inactive') | transloco }}
          </axpo-typography>
        </div>
        <div class="flex">
          <axpo-typography class="text-text-secondary w-32">
            {{ 'customer.Logo' | transloco }}:
          </axpo-typography>
          <img
            src="{{ customer.logo }}"
            alt="{{ customer.companyName }}"
            class="max-w-32 max-h-32"
          />
        </div>
      </div>
      <axpo-button (click)="onCustomerEditClick()">
        <i class="pr-1 fas fa-plus"></i>
        {{ 'customer.EditCustomer' | transloco }}
      </axpo-button>
    </div>

    <app-contract-management [customerId]="customer.id" />

    <app-trailer-management [customerId]="customer.id!" />
  </div>
}
