import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoSpinnerComponent } from '../../../core/axpo-spinner/axpo-spinner.component';
import { AxpoStepperComponent } from '../../../core/axpo-stepper/axpo-stepper.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { IOrder } from '../../../shared/models/api_models';
import { UserService } from '../../../shared/services/user-data.service';
import { CollectionTimeComponent } from '../collection-time/collection-time.component';
import { EditTrailerComponent } from '../edit-trailer/edit-trailer.component';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { OrderService } from '../order.service';
import { SummaryComponent } from '../summary/summary.component';

@Component({
  selector: 'app-edit-order',
  standalone: true,
  imports: [
    AxpoTypographyComponent,
    AxpoButtonComponent,
    AxpoStepperComponent,
    AxpoSpinnerComponent,
    SummaryComponent,
    OrderDetailsComponent,
    CollectionTimeComponent,
    EditTrailerComponent,
    TranslocoPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './edit-order.component.html',
  styleUrl: './edit-order.component.css',
})
export class EditOrderComponent implements OnDestroy {
  private orderService = inject(OrderService);
  private activatedRoute = inject(ActivatedRoute);
  private userService = inject(UserService);
  activeStep = signal<number>(0);
  order = signal<IOrder | undefined>(undefined);

  constructor() {
    this.orderService.loadPlants();
    this.activatedRoute.params.subscribe(params => {
      this.orderService.loadOrder(params['id']).subscribe(order => {
        this.order.set(order);
        this.orderService.orderId.set(order.id);
        this.orderService.selectedAmount.set(order.amount);
        this.orderService.selectedCustomerId.set(order.companyId);
      });
    });

    effect(() => {
      const plants = this.orderService.plants();
      const order = this.order();
      untracked(() => {
        if (plants.length > 0 && order) {
          this.orderService.selectedPlantId.set(order.plantId);
        }
      });
    });

    effect(() => {
      const contracts = this.orderService.contracts();
      const order = this.order();
      untracked(() => {
        if (contracts.length > 0 && order) {
          this.orderService.selectedContractId.set(order.contractId);
        }
      });
    });

    effect(() => {
      const connectorTypes = this.orderService.connectorTypes();
      const order = this.order();
      untracked(() => {
        if (connectorTypes.length > 0 && order) {
          this.orderService.selectedConnectorType.set(order.connectorType);
        }
      });
    });

    effect(() => {
      const trailers = this.orderService.trailers();
      const order = this.order();
      untracked(() => {
        if (trailers.length > 0 && order) {
          this.orderService.selectedTrailerId.set(order.trailerId);
        }
      });
    });

    effect(() => {
      const timeSlots = this.orderService.timeSlots();
      const order = this.order();
      untracked(() => {
        if (timeSlots && order) {
          this.orderService.selectedTimeSlot.set({
            dropOff: order.dropOff,
            pickUp: order.pickUp,
          });
        }
      });
    });
  }

  canEditCompleteOrder = computed(() => {
    const order = this.order();
    if (!order) return undefined;

    if (order.statusName === 'booked') return true;
    if (this.userService.isOperator()) return true;
    return false;
  });

  ngOnDestroy(): void {
    this.orderService.resetSelections();
  }
}
