<div class="container px-2 mx-auto">
  <div class="flex mt-24">
    <axpo-typography [variant]="'body-l'" [weight]="'bold'">
      {{ 'order.Trailer' | transloco }}
    </axpo-typography>
    <div class="flex justify-end w-full gap-2">
      <axpo-dropdown
        [defaultText]="'customer.AllTrailers' | transloco"
        [showSelectedValue]="true"
        [items]="[
          { value: 'all', label: 'customer.AllTrailers' | transloco },
          { value: 'active', label: 'customer.ActiveTrailers' | transloco },
        ]"
        [type]="'filled'"
        [size]="'medium'"
        (onSelect)="onSelectActiveTrailers($event)"
      />
      <axpo-button (click)="onAddTrailerClick()">
        <i class="pr-1 fas fa-plus"></i>
        {{ 'customer.AddTrailer' | transloco }}
      </axpo-button>
    </div>
  </div>
  <div class="mt-20">
    <axpo-table
      [data]="this.trailerListData()"
      [columns]="columns()"
      [actions]="['edit']"
      [headerType]="'ghost'"
      [isLoading]="customerManagementService.isLoading()"
      [textIfEmpty]="'core.NoContentFound' | transloco"
      (onActionClick)="onActionClick($event)"
    />
    <axpo-tablenavigation
      [(page)]="page"
      [(pageSize)]="pageSize"
      [totalItems]="totalItems()"
      [style]="'default'"
      [itemsPerPage]="'core.itemsPerPage' | transloco"
    />
  </div>
</div>
