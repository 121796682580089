import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  model,
  signal,
  untracked,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { CustomerManagementService } from './customer-management.service';
import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoDropdownComponent } from '../../core/axpo-dropdown/axpo-dropdown.component';
import { AxpoSearchComponent } from '../../core/axpo-search/axpo-search.component';
import {
  AxpoTableComponent,
  IActionClick,
  ISort,
  ITableColumn,
  ITableRow,
} from '../../core/axpo-table/axpo-table.component';
import { AxpoTableNavigationComponent } from '../../core/axpo-tablenavigation/axpo-tablenavigation.component';
import { LanguageService } from '../../shared/services/lang.service';
import { debounceSet } from '../../shared/utils/debounce';

@Component({
  standalone: true,
  selector: 'app-customer-management',
  templateUrl: './customer-management.component.html',
  styleUrl: './customer-management.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AxpoTableComponent,
    AxpoTableNavigationComponent,
    TranslocoPipe,
    AxpoButtonComponent,
    AxpoDropdownComponent,
    FormsModule,
    AxpoSearchComponent,
  ],
})
export class CustomerManagementComponent {
  private translocoService = inject(TranslocoService);
  private router = inject(Router);
  private langService = inject(LanguageService);
  public userCustomerService = inject(CustomerManagementService);
  private toastrService = inject(ToastrService);

  page = signal<number>(1);
  pageSize = signal<number>(10);
  sort = signal<ISort>({ field: 'createdAt', direction: 'desc' });
  columns = signal<ITableColumn[]>([]);
  onlyActiveCustomers = signal<boolean>(false);

  searchValue = model<string | undefined>();

  // CompaniesList
  _userListApi = effect(() => {
    const page = this.page();
    const pageSize = this.pageSize();
    const sort = this.sort();
    const searchValue = this.searchValue() || null;
    const active = this.onlyActiveCustomers();
    untracked(() => {
      this.userCustomerService.loadCustomers(page, pageSize, sort, active, searchValue);
    });
  });

  _searchFilterEffect = effect(() => {
    const _searchValue = this.searchValue();
    const _active = this.onlyActiveCustomers();
    untracked(() => {
      this.page.set(1);
    });
  });

  customerListData = computed<ITableRow[]>(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    return this.userCustomerService.customers().data.map(customer => {
      return {
        id: { value: customer.id },
        customerLogo: {
          imageValue: customer.logo,
        },
        customerName: {
          bold_value: customer.companyName,
          link: customer.isOperator ? undefined : `/customer-management/${customer.id}`,
        },
        createdAt: { value: customer.createdAt },
        status: {
          value: customer.isActive
            ? this.translocoService.translate('customer.Active')
            : this.translocoService.translate('customer.Inactive'),
        },
      };
    });
  });

  // Interaction actions
  onAddCustomerClick(): void {
    this.router.navigate(['customer-management/new']);
  }

  onSearchValueChange(value: string | undefined): void {
    debounceSet<string | undefined>(value, 500, this.searchValue);
  }

  onSelectActiveCustomers(value: string): void {
    if (value === 'active') {
      this.onlyActiveCustomers.set(true);
    } else {
      this.onlyActiveCustomers.set(false);
    }
  }

  // Table
  _updateColumnsEffect = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    untracked(() => {
      this.columns.set(this.getColumns());
    });
  });

  totalItems = computed<number>(() => this.userCustomerService.customers()?.count || 0);

  onSortClick = ($event: ISort) => {
    if ($event.field === 'companyLogo') {
      const sort = { field: 'companyName', direction: $event.direction };
      this.sort.set(sort);
    } else {
      this.sort.set($event);
    }
  };

  onActionClick = ($event: IActionClick) => {
    if ($event.action === 'view') {
      if ($event.row['customerName'].link) {
        this.router.navigate([`customer-management/${$event.row['id'].value}`]);
      } else {
        const msg = this.translocoService.translate('customer.CannotViewOperator');
        this.toastrService.error(msg);
      }
    }
  };

  getColumns = (): ITableColumn[] => {
    return [
      {
        title: this.translocoService.translate('customer.Customer'),
        field: 'customerLogo',
        formatter: ['image'],
        sortable: true,
      },
      {
        title: '',
        field: 'customerName',
        formatter: ['internal_link'],
      },
      {
        title: this.translocoService.translate('userManagement.Joined'),
        field: 'createdAt',
        sortable: true,
        formatter: ['date'],
      },
      {
        title: this.translocoService.translate('customer.Status'),
        field: 'status',
      },
    ];
  };
}
