import { ChangeDetectionStrategy, Component, computed, inject, output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { LocalizedDatePipe } from '../../../shared/pipes/localized-date.pipe';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-new-order-summary',
  templateUrl: './summary.component.html',
  standalone: true,
  imports: [AxpoTypographyComponent, AxpoButtonComponent, TranslocoPipe, LocalizedDatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './summary.component.css',
})
export class SummaryComponent {
  orderService = inject(OrderService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  private router = inject(Router);
  prevStep = output<void>();

  selectedPlantName = computed(() => {
    const plants = this.orderService.plants();
    return plants.find(plant => plant.id === this.orderService.selectedPlantId())?.name;
  });
  selectedContractName = computed(() => {
    const contracts = this.orderService.contracts();
    return contracts.find(contract => contract.id === this.orderService.selectedContractId())
      ?.contractName;
  });
  selectedTrailerName = computed(() => {
    const trailers = this.orderService.trailers();
    return trailers.find(trailer => trailer.id === this.orderService.selectedTrailerId())
      ?.trailerName;
  });

  back = () => {
    this.prevStep.emit();
  };

  submit = () => {
    this.orderService.submitOrder().subscribe({
      next: () => {
        const msg = this.translocoService.translate('order.submittedSuccessfully');
        this.toastr.success(msg);
        this.router.navigate(['/order']);
      },
      error: () => {
        const msg = this.translocoService.translate('order.errorWhileSubmitting');
        this.toastr.error(msg);
      },
    });
  };
}
