<div class="flex flex-col items-stretch min-h-screen">
  <axpo-header
    [title]="'core.GreenGasCustomerPortal' | transloco"
    [user]="user()"
    [availableLanguages]="availableLanguages"
    [(selectedLanguageCode)]="activeLanguage"
    (onLogout)="logout()"
  />

  <main class="grow shrink-0 text-text-primary">
    <router-outlet />
  </main>
  <axpo-footer [footerLinks]="footerLinks()" [version]="version" />
</div>
