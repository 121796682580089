import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'timeFormat',
  pure: true,
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: string): string {
    // Ensure the value is a valid time string (HH:mm:ss)
    if (!value || !value.includes(':')) {
      return value; // Return the original value if not valid
    }

    // Split the time string and return only the HH:mm part
    const [hours, minutes] = value.split(':');
    return `${hours}:${minutes}`;
  }
}
