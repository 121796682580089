import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';

import { LanguageService } from '../services/lang.service';

@Pipe({
  standalone: true,
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  langService = inject(LanguageService);
  datePipe = inject(DatePipe);

  transform(value: Date | string, pattern: string): any {
    const lang = this.langService.getLangSignal()() || 'en';
    return this.datePipe.transform(value, pattern, undefined, lang);
  }
}
