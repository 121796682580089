import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  model,
  output,
  signal,
  untracked,
} from '@angular/core';
import { Router } from '@angular/router';

import { AxpoProfileSubmenuComponent } from './axpo-profile-submenu/axpo-profile-submenu.component';
import { ClickOutsideDirective } from '../../shared/directives/click-outside.directive';
import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';

export type IconTypes = 'gear' | 'house' | 'user';

export interface ISubOption {
  label: string;
  value?: string;
  link?: string;
}

export interface ICustomAction {
  label: string;
  icon: IconTypes;
  value?: string;
  suboptions?: ISubOption[];
  link?: string;
}

export interface ILanguage {
  label: string;
  value: string;
}

export interface IUser {
  name: string;
  email: string;
}

@Component({
  selector: 'axpo-profile-dropdown',
  standalone: true,
  imports: [
    NgClass,
    ClickOutsideDirective,
    NgTemplateOutlet,
    AxpoProfileSubmenuComponent,
    AxpoTypographyComponent,
  ],
  templateUrl: './axpo-profile-dropdown.component.html',
  styleUrl: './axpo-profile-dropdown.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('transformOpacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class AxpoProfileDropdownComponent {
  router = inject(Router);

  profileImage = input<string | undefined>(undefined); // if undefined, placeholder image is used
  showLogout = input<boolean>(true);
  availableLanguages = input<ILanguage[]>([]);
  selectedLanguageCode = model<string | undefined>(undefined); // if undefined, first language is auto selected
  customActions = input<ICustomAction[]>([]);
  user = input<IUser | undefined>(undefined);
  onLogout = output<void>();
  onCustomAction = output<{ value: string; subvalue?: string }>();

  showMainMenu = signal<boolean>(false);
  showLanguageMenu = signal<boolean>(false);
  showSubMenu = signal<number | undefined>(undefined);

  _ = effect(() => {
    // close menu if language is selected
    this.selectedLanguageCode();
    untracked(() => {
      this.showMainMenu.set(false);
    });
  });

  __ = effect(() => {
    // auto select first language if none is selected yet
    const selectedLanguageCode = this.selectedLanguageCode();
    const availableLanguages = this.availableLanguages();
    untracked(() => {
      if (!selectedLanguageCode && availableLanguages.length > 0) {
        this.selectedLanguageCode.set(availableLanguages[0].value);
      }
    });
  });

  selectedLanguageLabel = computed(() => {
    const selectedLanguage = this.availableLanguages().find(
      lang => lang.value === this.selectedLanguageCode(),
    );
    return selectedLanguage ? selectedLanguage.label : '';
  });

  selectLogout = () => {
    this.onLogout.emit();
    this.closeMenus();
  };

  toggleMainMenu = () => {
    if (this.showMainMenu()) {
      this.closeMenus();
    } else {
      this.showMainMenu.set(true);
    }
  };

  toggleLanuageMenu = () => {
    this.showLanguageMenu.set(!this.showLanguageMenu());
    this.showSubMenu.set(undefined);
  };

  closeMenus = () => {
    this.showMainMenu.set(false);
    this.showSubMenu.set(undefined);
    this.showLanguageMenu.set(false);
  };

  clickOption = (action: ICustomAction, index: number) => {
    if (action.suboptions) {
      if (this.showSubMenu() == index) {
        this.showSubMenu.set(undefined);
      } else {
        this.showLanguageMenu.set(false);
        this.showSubMenu.set(index);
      }
    } else if (action.link) {
      this.closeMenus();
      this.router.navigate([action.link]);
    } else if (action.value) {
      this.closeMenus();
      this.onCustomAction.emit({ value: action.value });
    }
  };

  clickSuboption = (value?: string, suboption?: ISubOption) => {
    this.closeMenus();
    if (value && suboption?.link) {
      this.router.navigate([suboption.link]);
    } else if (value) {
      this.onCustomAction.emit({ value: value, subvalue: suboption?.value });
    }
  };

  clickLanguage = (option: ISubOption) => {
    this.closeMenus();
    this.selectedLanguageCode.set(option.value);
  };
}
