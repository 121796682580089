import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { CustomerManagementService } from '../customer-management.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AxpoButtonComponent, AxpoFormElementComponent, AxpoTypographyComponent, TranslocoPipe],
  selector: 'app-add-edit-contract',
  templateUrl: './add-edit-contract.component.html',
  styleUrl: './add-edit-contract.component.css',
})
export class AddEditContractComponent implements OnDestroy {
  private router = inject(Router);
  private customerManagementService = inject(CustomerManagementService);
  private activatedRoute = inject(ActivatedRoute);

  customerId!: string;
  contractId = signal<string | undefined>(undefined);
  contractName = signal<string | undefined>(undefined);
  plantIds = signal<string[]>([]);
  isActive = signal<boolean>(true);
  start = signal<string | undefined>(undefined);
  end = signal<string | undefined>(undefined);
  totalAmount = signal<number | undefined>(undefined);
  pricePerKg = signal<number | undefined>(undefined);
  minAmountPerOrder = signal<number | undefined>(undefined);
  maxAmountPerOrder = signal<number | undefined>(undefined);
  orderFreezeInH = signal<number | undefined>(undefined);
  pickUpBufferInH = signal<number | undefined>(undefined);
  endurContractId = signal<string | undefined>(undefined);
  oldPlantIds: string[] = [];

  constructor() {
    if (this.customerManagementService.availablePlants().length === 0) {
      this.customerManagementService.loadPlants();
    }

    this.activatedRoute.params.subscribe((params: Params) => {
      this.customerId = params.customerId;

      if (params.id) {
        this.customerManagementService.loadContract(params.id);
      }
    });
  }

  _contractEffect = effect(() => {
    const contract = this.customerManagementService.contract();
    untracked(() => {
      if (contract) {
        this.contractId.set(contract.id);
        this.contractName.set(contract.contractName);
        this.plantIds.set(contract.plantIds);
        this.isActive.set(contract.isActive);
        this.start.set(contract.start.replace('T00:00:00', ''));
        this.end.set(contract.end.replace('T00:00:00', ''));
        this.totalAmount.set(contract.totalAmount);
        this.pricePerKg.set(contract.pricePerKg);
        this.minAmountPerOrder.set(contract.minAmountPerOrder);
        this.maxAmountPerOrder.set(contract.maxAmountPerOrder);
        this.orderFreezeInH.set(contract.orderFreezeInH);
        this.pickUpBufferInH.set(contract.pickUpBufferInH);
        this.endurContractId.set(contract.endurContractId);
        this.oldPlantIds = contract.plantIds;
      }
    });
  });

  plants = computed(() => {
    return this.customerManagementService
      .availablePlants()
      .map(plant => {
        return { value: plant.id, label: plant.name };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  });

  isFormValid = computed(() => {
    return (
      (this.contractName() !== this.customerManagementService.contract()?.contractName ||
        this.plantIds() !== this.customerManagementService.contract()?.plantIds ||
        this.start() !== this.customerManagementService.contract()?.start.split('T')[0] ||
        this.end() !== this.customerManagementService.contract()?.end.split('T')[0] ||
        this.totalAmount()?.toString() !==
          this.customerManagementService.contract()?.totalAmount.toString() ||
        this.pricePerKg()?.toString() !==
          this.customerManagementService.contract()?.pricePerKg.toString() ||
        this.minAmountPerOrder()?.toString() !==
          this.customerManagementService.contract()?.minAmountPerOrder.toString() ||
        this.maxAmountPerOrder()?.toString() !==
          this.customerManagementService.contract()?.maxAmountPerOrder.toString() ||
        this.orderFreezeInH()?.toString() !==
          this.customerManagementService.contract()?.orderFreezeInH.toString() ||
        this.pickUpBufferInH()?.toString() !==
          this.customerManagementService.contract()?.pickUpBufferInH.toString() ||
        this.endurContractId() !== this.customerManagementService.contract()?.endurContractId ||
        this.isActive() !== this.customerManagementService.contract()?.isActive) &&
      this.contractName() &&
      this.plantIds().length > 0 &&
      this.start() &&
      this.end() &&
      this.totalAmount() &&
      this.pricePerKg() &&
      this.minAmountPerOrder() &&
      this.maxAmountPerOrder() &&
      this.orderFreezeInH() &&
      this.pickUpBufferInH() &&
      Number(this.totalAmount()!) >= 0 &&
      Number(this.pricePerKg()!) >= 0 &&
      Number(this.minAmountPerOrder()!) >= 0 &&
      Number(this.maxAmountPerOrder()!) >= 0 &&
      Number(this.orderFreezeInH()!) >= 0 &&
      Number(this.pickUpBufferInH()!) >= 0 &&
      Number(this.minAmountPerOrder()!) <= Number(this.maxAmountPerOrder()!) &&
      Number(this.maxAmountPerOrder()!) <= Number(this.totalAmount()!) &&
      this.start()! < this.end()!
    );
  });

  submit = () => {
    if (this.isFormValid()) {
      if (this.contractId()) {
        const plantIdAdd = this.plantIds().filter(
          connectorType => !this.oldPlantIds.includes(connectorType),
        );
        const plantIdRemove = this.oldPlantIds.filter(
          connectorType => !this.plantIds().includes(connectorType),
        );

        this.customerManagementService.updateContract(
          this.contractId()!,
          this.customerId,
          this.contractName()!,
          plantIdAdd,
          plantIdRemove,
          this.isActive(),
          `${this.start()!} 00:00:00`,
          `${this.end()!} 00:00:00`,
          this.totalAmount()!,
          this.pricePerKg()!,
          this.minAmountPerOrder()!,
          this.maxAmountPerOrder()!,
          this.orderFreezeInH()!,
          this.pickUpBufferInH()!,
          this.endurContractId()!,
        );
      } else {
        this.customerManagementService.createContract(
          this.customerId,
          this.contractName()!,
          this.plantIds(),
          this.isActive(),
          `${this.start()!} 00:00:00`,
          `${this.end()!} 00:00:00`,
          this.totalAmount()!,
          this.pricePerKg()!,
          this.minAmountPerOrder()!,
          this.maxAmountPerOrder()!,
          this.orderFreezeInH()!,
          this.pickUpBufferInH()!,
          this.endurContractId()!,
        );
      }
    }
  };

  cancel = () => {
    this.router.navigate(['/customer-management', this.customerId]);
  };

  ngOnDestroy() {
    this.customerManagementService.resetSelections();
  }
}
