<div class="container mx-auto">
  <div class="mt-20">
    <axpo-table
      [data]="listData()"
      [columns]="columns()"
      [headerType]="'ghost'"
      [isLoading]="orderListService.isLoading()"
      [textIfEmpty]="'core.NoContentFound' | transloco"
      (onSortClick)="onSortClick($event)"
      (onActionClick)="onActionClick($event)"
    />
    <axpo-tablenavigation
      [(page)]="page"
      [(pageSize)]="pageSize"
      [totalItems]="totalItems()"
      [style]="'default'"
      [itemsPerPage]="'core.itemsPerPage' | transloco"
    />
  </div>
</div>

@if (showDeleteDialog()) {
  <axpo-dialog
    [title]="'orderList.DeleteOrder' | transloco"
    [confirmText]="'core.Delete' | transloco"
    [cancelText]="'core.Cancel' | transloco"
    (takenAction)="takeActionOnDeleteDialog($event)"
    icon="warning"
  >
    {{ 'orderList.AreYouSureYouWantToDeleteThisOrder' | transloco }}
  </axpo-dialog>
}
