import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { OidcSecurityService } from 'angular-auth-oidc-client';

import { AxpoFooterComponent, FooterLinksProps } from './core/axpo-footer/axpo-footer.component';
import { AxpoHeaderComponent } from './core/axpo-header/axpo-header.component';
import { ILanguage, IUser } from './core/axpo-profile-dropdown/axpo-profile-dropdown.component';
import { BreadcrumbService } from './shared/services/breadcrumb.service';
import { UserService } from './shared/services/user-data.service';
import { version } from '../../public/version';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AxpoHeaderComponent, AxpoFooterComponent, TranslocoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  private userService = inject(UserService);
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private translocoService = inject(TranslocoService);
  private breadcrumbService = inject(BreadcrumbService);

  version = version;
  availableLanguages: ILanguage[] = [
    { label: 'English', value: 'en' },
    { label: 'Deutsch', value: 'de' },
  ];
  activeLanguage = signal<string | undefined>(undefined);
  footerLinks = signal<FooterLinksProps[]>([]);
  user = signal<IUser | undefined>(undefined);

  userDataEffect = effect(() => {
    const user = this.userService.getUserData()();
    untracked(() => {
      const newValue = {
        name: `${user?.firstName} ${user?.lastName}`,
        email: user?.email || '',
      };
      this.user.set(newValue);
    });
  });

  _activeLangueEffect = effect(() => {
    const activeLanguage = this.activeLanguage();
    untracked(() => {
      if (activeLanguage) {
        this.translocoService.setActiveLang(activeLanguage);
        localStorage.setItem('language', activeLanguage);
        this.getFooterLinks();
        this.getBreadcrumbs();
      }
    });
  });

  ngOnInit() {
    this.loadLanguage();
    this.getFooterLinks();
    this.oidcSecurityService.checkAuth().subscribe({
      next: async loginResponse => {
        if (loginResponse && loginResponse.isAuthenticated) {
          console.info('login successful', loginResponse);
        } else {
          console.info('login unsuccessful');
        }
      },
      error: () => {
        console.info('error login');
      },
    });
  }

  loadLanguage = () => {
    const cachedLanguage = localStorage.getItem('language');
    if (cachedLanguage) {
      this.activeLanguage.set(cachedLanguage);
    } else {
      const defaultLanguage = this.translocoService.getActiveLang();
      this.activeLanguage.set(defaultLanguage);
    }
  };

  getFooterLinks = () => {
    const links: FooterLinksProps[] = [
      {
        text: this.translocoService.translate('core.Imprint'),
        link: 'https://www.axpo.com/group/' + this.activeLanguage() + '/impressum.html',
        target: 'external_link',
      },
      {
        text: this.translocoService.translate('core.LegalInformation'),
        link: 'https://www.axpo.com/group/' + this.activeLanguage() + '/legal-information.html',
        target: 'external_link',
      },
      {
        text: this.translocoService.translate('core.PrivacyPolicy'),
        link: 'https://www.axpo.com/group/' + this.activeLanguage() + '/privacy-policy.html',
        target: 'external_link',
      },
    ];
    this.footerLinks.set(links);
  };

  getBreadcrumbs = () => {
    const translatedBreadcrumb = {
      Home: this.translocoService.translate('breadcrumbs.Home'),
      Unauthorized: this.translocoService.translate('breadcrumbs.Unauthorized'),
      Login: this.translocoService.translate('breadcrumbs.Login'),
      'Order List': this.translocoService.translate('breadcrumbs.orderList'),
      'Customer Management': this.translocoService.translate('breadcrumbs.CustomerManagement'),
      'User Management': this.translocoService.translate('breadcrumbs.UserManagement'),
      New: this.translocoService.translate('breadcrumbs.New'),
      Overview: this.translocoService.translate('breadcrumbs.Overview'),
      'Plant Management': this.translocoService.translate('breadcrumbs.PlantManagement'),
      'Opening Hours': this.translocoService.translate('breadcrumbs.OpeningHours'),
      Contract: this.translocoService.translate('breadcrumbs.Contract'),
      Trailer: this.translocoService.translate('breadcrumbs.Trailer'),
    };
    this.breadcrumbService.replaceBreadcrumbs(translatedBreadcrumb);
  };

  logout = () => {
    console.log('logout');
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(result => console.info(result));
  };
}
