<div class="container py-4 mx-auto w-[420px]">
  <axpo-typography variant="title-xl">
    {{ 'plantManagement.EditHoursTitle' | transloco }}
  </axpo-typography>
  @if (this.plantManagementService.isLoading()) {
    <div class="mt-16">
      <axpo-spinner>{{ 'core.Loading' | transloco }}</axpo-spinner>
    </div>
  } @else {
    <div class="mt-6">
      <axpo-typography variant="title-l" class="mt-6">
        {{ weekDay() }}
      </axpo-typography>
    </div>
    <div class="w-32 my-3">
      <axpo-form-element
        formType="time"
        [label]="'plantManagement.OpeningTime' | transloco"
        [(value)]="openingTime"
      />
    </div>

    <div class="w-32 my-3">
      <axpo-form-element
        formType="time"
        [label]="'plantManagement.ClosingTime' | transloco"
        [(value)]="closeTime"
      />
    </div>

    <div class="my-6">
      <axpo-form-element
        formType="checkbox"
        [label]="'plantManagement.IsOperating' | transloco"
        [(value)]="IsOperating"
      />
    </div>

    <div class="flex justify-end gap-3 mt-20 mb-3">
      <axpo-button (click)="cancel()" type="ghost" [style]="'secondary'">
        {{ 'core.Cancel' | transloco }}
      </axpo-button>

      <axpo-button (click)="submit()" [disabled]="!isFormValid()">
        {{ 'plantManagement.EditHours' | transloco }}
      </axpo-button>
    </div>
  }
</div>
