<div class="max-w-screen-lg mx-auto py-4">
  <axpo-typography variant="title-xl">{{ 'order.PlaceYourOrder' | transloco }}</axpo-typography>

  <axpo-stepper
    [steps]="[
      'order.OrderDetails' | transloco,
      'order.CollectionTime' | transloco,
      'order.Confirmation' | transloco,
    ]"
    [(activeStep)]="activeStep"
  />

  @if (activeStep() === 0) {
    <app-order-details (nextStep)="activeStep.set(1)" />
  } @else if (activeStep() === 1) {
    <app-collection-time (nextStep)="activeStep.set(2)" (prevStep)="activeStep.set(0)" />
  } @else if (activeStep() === 2) {
    <app-new-order-summary (prevStep)="activeStep.set(1)" />
  }
</div>
