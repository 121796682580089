@if (userManagementService.userRolesForSelect().length > 0) {
  <div class="py-4 mx-auto w-96">
    <axpo-typography variant="title-xl">
      @if (userId()) {
        {{ 'userManagement.EditUser' | transloco }}
      } @else {
        {{ 'userManagement.AddUser' | transloco }}
      }
    </axpo-typography>

    <div class="my-3 w-96">
      <axpo-form-element
        formType="text"
        [label]="'userManagement.Email' | transloco"
        [(value)]="email"
        [disabled]="userId() ? true : false"
      />
      @if (!emailIsValid()) {
        <axpo-typography variant="body-s" class="text-primary">
          {{ 'userManagement.InvalidEmail' | transloco }}
        </axpo-typography>
      }
    </div>

    @if (showCompanySelection()) {
      <div class="my-3 w-96">
        <axpo-form-element
          formType="select"
          [label]="'userManagement.Company' | transloco"
          [options]="userManagementService.availableCustomers()"
          [(value)]="companyId"
          [disabled]="userManagementService.availableCustomers().length === 0"
        />
      </div>
    }
    <div class="my-3 w-96">
      <axpo-form-element
        formType="multiselect"
        [label]="'userManagement.AllRoles' | transloco"
        [options]="availableRoles()"
        [(value)]="selectedRoles"
        [disabled]="availableRoles().length === 0"
      />
    </div>

    <div class="flex justify-end gap-3 mb-3">
      <axpo-button (click)="cancel()" type="ghost" [style]="'secondary'">
        {{ 'core.Cancel' | transloco }}
      </axpo-button>

      @if (userId()) {
        <div class="flex flex-col items-end gap-12">
          <axpo-button (click)="updateUser()" [disabled]="!isFormValid()">
            {{ 'userManagement.EditUser' | transloco }}
          </axpo-button>
          <axpo-button (click)="askToDeleteUser()">
            {{ 'userManagement.DeleteUser' | transloco }}
          </axpo-button>
        </div>
      } @else {
        <axpo-button (click)="createUser()" [disabled]="!isFormValid()">
          {{ 'userManagement.AddUser' | transloco }}
        </axpo-button>
      }
    </div>
  </div>
}

@if (showDeleteDialog()) {
  <axpo-dialog
    [title]="'userManagement.DeleteUser' | transloco"
    [confirmText]="'core.Delete' | transloco"
    [cancelText]="'core.Cancel' | transloco"
    (takenAction)="takeActionOnDeleteDialog($event)"
    icon="warning"
  >
    {{ 'userManagement.AreYouSureYouWantToDeleteTheUser' | transloco }}
  </axpo-dialog>
}
