<div class="container px-2 mx-auto">
  <div class="flex mt-24">
    <div class="flex w-1/2">
      <axpo-search
        [placeholder]="'core.enterText' | transloco"
        (debounceValue)="onSearchValueChange($event)"
      ></axpo-search>
    </div>
    <div class="flex justify-end w-1/2 gap-2">
      <axpo-dropdown
        [defaultText]="'userManagement.AllRoles' | transloco"
        [showSelectedValue]="true"
        [items]="userRoles()"
        [type]="'filled'"
        [size]="'medium'"
        (onSelect)="onRoleSelect($event)"
      ></axpo-dropdown>
      <axpo-button (click)="onAddUserClick()">
        <i class="pr-1 fas fa-plus"></i>
        {{ 'userManagement.NewUser' | transloco }}
      </axpo-button>
    </div>
  </div>
  <div class="mt-20">
    <axpo-table
      [data]="this.userListData()"
      [columns]="columns()"
      [actions]="['edit']"
      [headerType]="'ghost'"
      [isLoading]="userManagementService.isLoading()"
      [textIfEmpty]="'core.NoContentFound' | transloco"
      (onSortClick)="onSortClick($event)"
      (onActionClick)="onActionClick($event)"
    />
    <axpo-tablenavigation
      [(page)]="page"
      [(pageSize)]="pageSize"
      [totalItems]="totalItems()"
      [style]="'default'"
      [itemsPerPage]="'core.itemsPerPage' | transloco"
    />
  </div>
</div>
