import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  model,
  signal,
  untracked,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { twMerge } from 'tailwind-merge';

import { ClickOutsideDirective } from '../../shared/directives/click-outside.directive';
import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';

export type FormElementTypes =
  | 'text'
  | 'select'
  | 'multiselect'
  | 'number'
  | 'date'
  | 'file'
  | 'checkbox'
  | 'time';
export interface IOption {
  value: string;
  label: string;
}

@Component({
  selector: 'axpo-form-element',
  standalone: true,
  imports: [AxpoTypographyComponent, FormsModule, ClickOutsideDirective, NgTemplateOutlet, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-form-element.component.html',
  styleUrl: './axpo-form-element.component.css',
  animations: [
    trigger('transformOpacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class AxpoFormElementComponent {
  formType = input.required<FormElementTypes>();
  label = input<string | undefined>();
  value = model.required<string | string[] | number | File | boolean | undefined>();
  debounceValue = signal<string | string[] | number | File | boolean | undefined>(undefined);
  labelOfSelectValue = computed(() => {
    if (this.formType() === 'select' && this.options()) {
      return this.options()!.find(option => option.value === this.value())?.label;
    } else if (this.formType() === 'multiselect' && this.options()) {
      return this.options()!
        .filter(option => ((this.value() || []) as string[]).includes(option.value))
        .map(option => option.label)
        .join(', ');
    }
    return '';
  });
  options = input<IOption[]>();
  min = input<string | number | undefined>();
  max = input<string | number | undefined>();
  step = input<number | undefined>();
  disabled = input<boolean>(false);
  hasError = signal<boolean>(false);
  isSelectMenuOpen = signal<boolean>(false);

  computedStyle = computed(() => {
    let styles = [
      'border',
      'border-border',
      'rounded',
      'text-left',
      'relative',
      'focus:border-text-secondary',
      'focus:ring-0',
    ];
    if (this.formType() === 'checkbox') {
      styles = styles.concat(['w-7', 'h-7', 'text-primary', 'hover:cursor-pointer']);
    } else {
      styles = styles.concat(['block', 'w-full', 'h-10']);
    }
    if (this.hasError()) {
      styles = styles.concat(['border-interaction-red-2', 'text-interaction-red-2']);
    }
    if (this.isSelectMenuOpen()) {
      styles = styles.concat(['border-text-secondary']);
    }
    if (this.disabled()) {
      styles = styles.concat(['bg-background-1']);
    }
    return twMerge(styles);
  });

  _debounce = effect(onCleanup => {
    const value = this.value();
    untracked(() => {
      const timeout = setTimeout(() => this.debounceValue.set(value), 1000);
      onCleanup(() => clearTimeout(timeout));
    });
  });

  _ = effect(() => {
    let min: number | Date;
    let max: number | Date;
    let value: number | Date;
    if (this.formType() === 'date') {
      min = new Date(this.min() as string);
      max = new Date(this.max() as string);
      value = new Date(this.debounceValue() as string);
    } else {
      min = this.min() as number;
      max = this.max() as number;
      value = this.debounceValue() as number;
    }

    untracked(() => {
      if (min !== undefined && value !== undefined && value < min) {
        this.hasError.set(true);
      } else if (max !== undefined && value !== undefined && value > max) {
        this.hasError.set(true);
      } else {
        this.hasError.set(false);
      }
    });
  });

  fileAdded = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.value.set(input.files[0]);
      input.value = ''; // reset the input value to allow the same file to be selected again
    }
  };

  selectOption = (option: IOption) => {
    this.value.set(option.value);
    this.isSelectMenuOpen.set(false);
  };

  selectOptionMultiple = (option: IOption) => {
    const values = (this.value() || []) as string[];
    if (values.includes(option.value)) {
      this.value.set(values.filter(value => value !== option.value));
    } else {
      this.value.set([...values, option.value]);
    }
  };

  clearSelection = () => {
    if (this.formType() === 'multiselect') {
      this.value.set([]);
    } else {
      this.value.set(undefined);
    }
  };
}
