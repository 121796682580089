import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import {
  AxpoIconGalleryComponent,
  IconsProps,
} from '../../core/axpo-icon-gallery/axpo-icon-gallery.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import { LanguageService } from '../../shared/services/lang.service';
import { UserService } from '../../shared/services/user-data.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [TranslocoPipe, AxpoIconGalleryComponent, AxpoTypographyComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent {
  private translocoService = inject(TranslocoService);
  private langService = inject(LanguageService);
  private userService = inject(UserService);

  galleryIcons = computed(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    const rolesProps = this.userService.getUserRoles()();
    const roles = rolesProps ? rolesProps.map(role => role.role) : [];

    const icons: IconsProps[] = [];
    if (
      ['orderer', 'customer_admin', 'operator', 'operator_admin'].some(role => roles.includes(role))
    ) {
      icons.push({
        text: this.translocoService.translate('homepage.NewOrder'),
        path: 'images/new-order.png',
        alt: 'new order',
        link: '/order/new',
        target: 'internal_link',
      });
    }
    if (
      ['driver', 'orderer', 'customer_admin', 'operator', 'operator_admin'].some(role =>
        roles.includes(role),
      )
    ) {
      icons.push({
        text: this.translocoService.translate('homepage.OrderList'),
        path: 'images/order-list.png',
        alt: 'order list',
        link: '/order',
        target: 'internal_link',
      });
    }
    if (['customer_admin', 'operator', 'operator_admin'].some(role => roles.includes(role))) {
      icons.push({
        text: this.translocoService.translate('homepage.UserManagement'),
        path: 'images/user-management.png',
        alt: 'user management',
        link: '/user-management',
        target: 'internal_link',
      });
    }
    if (['operator', 'operator_admin'].some(role => roles.includes(role))) {
      icons.push({
        text: this.translocoService.translate('homepage.CustomerManagement'),
        path: 'images/customer-management.png',
        alt: 'customer management',
        link: '/customer-management',
        target: 'internal_link',
      });
    }
    if (['operator', 'operator_admin'].some(role => roles.includes(role))) {
      icons.push({
        text: this.translocoService.translate('homepage.PlantManagement'),
        path: 'images/customer-management.svg',
        alt: 'plant management',
        link: '/plant-management',
        target: 'internal_link',
      });
    }
    // icons.push({
    //   text: this.translocoService.translate('homepage.Notifications'),
    //   path: 'images/notifications.png',
    //   alt: 'notifications',
    //   link: '/404',
    //   target: 'external_link',
    // });
    if (['operator', 'operator_admin'].some(role => roles.includes(role))) {
      icons.push({
        text: this.translocoService.translate('homepage.InsightsCore'),
        path: 'images/axpo.svg',
        alt: 'insights',
        link: 'https://insights.grid.axpo.com/modules/search',
        target: 'external_link',
      });
      icons.push({
        text: this.translocoService.translate('homepage.InsightsAnalytics'),
        path: 'images/axpo.svg',
        alt: 'insights',
        link: 'https://www.hydro-insights.com/de/tenant/8f4a27b6-361c-4f89-a698-692772d33e97',
        target: 'external_link',
      });
    }
    return icons;
  });
}
