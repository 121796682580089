import { ChangeDetectionStrategy, Component, OnDestroy, inject, signal } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoStepperComponent } from '../../../core/axpo-stepper/axpo-stepper.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { CollectionTimeComponent } from '../collection-time/collection-time.component';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { OrderService } from '../order.service';
import { SummaryComponent } from '../summary/summary.component';

@Component({
  selector: 'app-new-order',
  standalone: true,
  imports: [
    AxpoTypographyComponent,
    AxpoButtonComponent,
    AxpoStepperComponent,
    SummaryComponent,
    OrderDetailsComponent,
    CollectionTimeComponent,
    TranslocoPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './new-order.component.html',
  styleUrl: './new-order.component.css',
})
export class NewOrderComponent implements OnDestroy {
  private orderService = inject(OrderService);
  activeStep = signal<number>(0);

  ngOnDestroy(): void {
    this.orderService.resetSelections();
  }
}
