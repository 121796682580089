import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';

@Component({
  selector: 'axpo-stepper',
  standalone: true,
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-stepper.component.html',
  styleUrl: './axpo-stepper.component.css',
})
export class AxpoStepperComponent {
  steps = input.required<string[]>();
  activeStep = model.required<number>();
}
