<div class="w-96 mx-auto py-4">
  <axpo-typography variant="title-xl">
    @if (customerId()) {
      {{ 'customer.EditCustomer' | transloco }}
    } @else {
      {{ 'customer.AddCustomer' | transloco }}
    }
  </axpo-typography>
  <div class="my-3 w-96">
    <axpo-form-element
      formType="text"
      [label]="'customer.CustomerName' | transloco"
      [(value)]="customerName"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="text"
      [label]="'customer.ContactPerson' | transloco"
      [(value)]="contactPerson"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="text"
      [label]="'customer.ContactEmail' | transloco"
      [(value)]="contactEmail"
    />
    @if (!emailIsValid()) {
      <axpo-typography variant="body-s" class="text-primary">
        {{ 'customer.InvalidEmail' | transloco }}
      </axpo-typography>
    }
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="text"
      [label]="'customer.ContactPhone' | transloco"
      [(value)]="contactPhone"
    />
    @if (!phoneIsValid()) {
      <axpo-typography variant="body-s" class="text-primary">
        {{ 'customer.InvalidPhone' | transloco }}
      </axpo-typography>
    }
  </div>

  <div class="my-3 w-96">
    <axpo-form-element formType="file" [label]="'customer.Logo' | transloco" [(value)]="logo" />
    @if (!logoIsValid()) {
      <axpo-typography variant="body-s" class="text-primary">
        {{ 'customer.InvalidImage' | transloco }}
      </axpo-typography>
    }
    <canvas #canvas width="0" height="0" class="my-3"></canvas>
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="checkbox"
      [label]="'customer.IsActive' | transloco"
      [(value)]="isActive"
    />
  </div>

  <div class="flex justify-end gap-3 mb-3">
    <axpo-button (click)="cancel()" type="ghost" [style]="'secondary'">
      {{ 'core.Cancel' | transloco }}
    </axpo-button>

    <axpo-button (click)="submit()" [disabled]="!isFormValid()">
      @if (customerId()) {
        {{ 'customer.EditCustomer' | transloco }}
      } @else {
        {{ 'customer.AddCustomer' | transloco }}
      }
    </axpo-button>
  </div>
</div>
