import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoSpinnerComponent } from '../../../../core/axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../../../../core/axpo-typography/axpo-typography.component';
import { IOperatingHours } from '../../../../shared/models/api_models';
import { LanguageService } from '../../../../shared/services/lang.service';
import { formatTime, formatTimeToISO } from '../../../../shared/utils/formatTime';
import { PlantManagementService } from '../../plant-management.service';

@Component({
  standalone: true,
  selector: 'app-edit-opening-hours',
  templateUrl: './edit-opening-hours.component.html',
  styleUrl: './edit-opening-hours.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AxpoTypographyComponent,
    AxpoFormElementComponent,
    AxpoButtonComponent,
    TranslocoPipe,
    AxpoSpinnerComponent,
  ],
})
export class EditOpeningHoursComponent implements OnDestroy {
  private router = inject(Router);
  public plantManagementService = inject(PlantManagementService);
  private activatedRoute = inject(ActivatedRoute);
  private translocoService = inject(TranslocoService);
  private langService = inject(LanguageService);

  openingTime = signal<string | undefined>(undefined);
  closeTime = signal<string | undefined>(undefined);
  IsOperating = signal<boolean>(true);
  weekDay = signal<string>('');

  dayId = signal<string | undefined>(undefined);
  plantId = signal<string | undefined>(undefined);

  _onInit = effect(
    () => {
      const routeParam = this.activatedRoute.snapshot.paramMap.get('id');
      const plantId = this.activatedRoute.snapshot.paramMap.get('plantId');
      if (routeParam && plantId && routeParam !== this.dayId()) {
        this.dayId.set(routeParam);
        this.plantId.set(plantId);
        this.plantManagementService.loadOpeningHourDay(routeParam);
      }
    },
    { allowSignalWrites: true },
  );

  _hourDataEffect = effect(() => {
    const day = this.plantManagementService.openingHourDay();
    const _activeLanguage = this.langService.getLangSignal()();
    untracked(() => {
      if (Object.keys(day).length === 0) return;
      this.openingTime.set(formatTime(day.startTime));
      this.closeTime.set(formatTime(day.endTime));
      this.IsOperating.set(day.operating);
      this.weekDay.set(this.translocoService.translate(`plantManagement.${day.day}`));
    });
  });

  cancel = () => {
    this.router.navigate([`/plant-management/${this.plantId()}`]);
  };

  isFormValid = computed(() => {
    return (
      (this.openingTime() !== this.plantManagementService.openingHourDay().startTime?.slice(0, 5) ||
        this.closeTime() !== this.plantManagementService.openingHourDay().endTime?.slice(0, 5) ||
        this.IsOperating() !== this.plantManagementService.openingHourDay().operating) &&
      this.openingTime() &&
      this.closeTime() &&
      this.closeTime()! > this.openingTime()!
    );
  });

  submit = () => {
    if (this.isFormValid() && this.dayId() && this.plantId()) {
      this.plantManagementService.updateOpeningHourDay(
        this.dayId()!,
        formatTimeToISO(this.openingTime()!),
        formatTimeToISO(this.closeTime()!),
        this.IsOperating(),
        this.plantId()!,
      );
    }
  };

  ngOnDestroy(): void {
    this.openingTime.set('');
    this.closeTime.set('');
    this.IsOperating.set(true);
    this.weekDay.set('');
    this.dayId.set('');
    this.plantId.set('');
    this.plantManagementService.openingHourDay.set({} as IOperatingHours);
  }
}
