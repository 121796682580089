import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { CustomerManagementService } from '../customer-management.service';

@Component({
  selector: 'app-add-edit-trailer',
  standalone: true,
  imports: [AxpoFormElementComponent, AxpoButtonComponent, AxpoTypographyComponent, TranslocoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './add-edit-trailer.component.html',
  styleUrl: './add-edit-trailer.component.css',
})
export class AddEditTrailerComponent implements OnDestroy {
  private router = inject(Router);
  private customerManagementService = inject(CustomerManagementService);
  private activatedRoute = inject(ActivatedRoute);

  customerId!: string;
  trailerId = signal<string | undefined>(undefined);
  trailerName = signal<string | undefined>(undefined);
  isActive = signal<boolean>(true);
  capacity = signal<number | undefined>(undefined);
  connectorTypes = signal<string[]>([]);
  oldConnectorTypes: string[] = [];

  constructor() {
    if (this.customerManagementService.availableConnectorTypes().length === 0) {
      this.customerManagementService.loadConnectorTypes();
    }

    this.activatedRoute.params.subscribe((params: Params) => {
      this.customerId = params.customerId;

      if (params.id) {
        this.customerManagementService.loadTrailer(params.id);
      }
    });
  }

  _trailerEffect = effect(() => {
    const trailer = this.customerManagementService.trailer();
    untracked(() => {
      if (trailer) {
        this.trailerId.set(trailer.id);
        this.trailerName.set(trailer.trailerName);
        this.isActive.set(trailer.isActive);
        this.capacity.set(trailer.capacity);
        this.connectorTypes.set(trailer.connectorTypes);
        this.oldConnectorTypes = trailer.connectorTypes;
      }
    });
  });

  availableConnectorTypes = computed(() => {
    return this.customerManagementService
      .availableConnectorTypes()
      .map(type => {
        return { value: type, label: type };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  });

  isFormValid = computed(() => {
    return (
      (this.trailerName() !== this.customerManagementService.trailer()?.trailerName ||
        this.capacity()?.toString() !==
          this.customerManagementService.trailer()?.capacity.toString() ||
        this.connectorTypes() !== this.customerManagementService.trailer()?.connectorTypes ||
        this.isActive() !== this.customerManagementService.trailer()?.isActive) &&
      this.trailerName() &&
      this.capacity() &&
      this.connectorTypes().length > 0 &&
      Number(this.capacity()) > 0
    );
  });

  submit = () => {
    if (this.isFormValid()) {
      if (this.trailerId()) {
        const connectorsTypesAdd = this.connectorTypes().filter(
          connectorType => !this.oldConnectorTypes.includes(connectorType),
        );
        const connectorsTypesRemove = this.oldConnectorTypes.filter(
          connectorType => !this.connectorTypes().includes(connectorType),
        );

        this.customerManagementService.updateTrailer(
          this.trailerId()!,
          this.trailerName()!,
          this.isActive(),
          this.capacity()!,
          connectorsTypesAdd,
          connectorsTypesRemove,
          this.customerId,
        );
      } else {
        this.customerManagementService.createTrailer(
          this.trailerName()!,
          this.isActive(),
          this.capacity()!,
          this.connectorTypes()!,
          this.customerId,
        );
      }
    }
  };

  cancel = () => {
    this.router.navigate(['/customer-management', this.customerId]);
  };

  ngOnDestroy() {
    this.customerManagementService.resetSelections();
  }
}
