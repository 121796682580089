import { Injectable, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ISort } from '../../core/axpo-table/axpo-table.component';
import { IOrder, IPage } from '../../shared/models/api_models';
import { ApiService } from '../../shared/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class OrderListService {
  private translocoService = inject(TranslocoService);
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  isLoading = signal<boolean>(false);
  orderList = signal<IPage<IOrder>>({ count: 0, data: [] });

  public loadOrdersList = (page: number, pageSize: number, sortBy: ISort) => {
    if (this.orderList().count == 0) this.isLoading.set(true);
    this.apiService
      .get<
        IPage<IOrder>
      >(`api/v1/order?orderBy=${sortBy.field}&sortDirection=${sortBy.direction}&currentPage=${page}&pageSize=${pageSize}`)
      .subscribe({
        next: list => {
          this.orderList.set(list);
        },
        error: () => {
          const msg = this.translocoService.translate('orderList.orderListFailed');
          this.toastr.error(msg);
        },
        complete: () => {
          this.isLoading.set(false);
        },
      });
  };

  public deleteOrder = (orderId: string) => {
    this.apiService.delete(`api/v1/order/${orderId}`).subscribe({
      next: () => {
        const msg = this.translocoService.translate('orderList.orderDeleted');
        this.toastr.success(msg);
        this.orderList.update(list => {
          return { ...list, data: list.data.filter(order => order.id !== orderId) };
        });
      },
      error: () => {
        const msg = this.translocoService.translate('orderList.orderDeleteFailed');
        this.toastr.error(msg);
      },
    });
  };

  public resetOrderList = () => {
    this.orderList.set({ count: 0, data: [] });
  };
}
