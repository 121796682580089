import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../../core/axpo-form-element/axpo-form-element.component';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-edit-trailer',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AxpoButtonComponent, AxpoFormElementComponent, TranslocoPipe],
  templateUrl: './edit-trailer.component.html',
  styleUrl: './edit-trailer.component.css',
})
export class EditTrailerComponent {
  public orderService = inject(OrderService);
  private router = inject(Router);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  initialTrailerId = signal<string | undefined>(undefined);

  _ngOnInit = effect(() => {
    const trailerId = this.orderService.selectedTrailerId();
    untracked(() => {
      if (this.initialTrailerId() === undefined) {
        this.initialTrailerId.set(trailerId);
      }
    });
  });

  trailerSelectOptions = computed(() =>
    this.orderService
      .trailers()
      .map(trailer => ({ value: trailer.id, label: trailer.trailerName }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  );

  cancel = () => {
    this.router.navigate(['/order']);
  };

  submit = () => {
    this.orderService.changeTrailer().subscribe({
      next: () => {
        const msg = this.translocoService.translate('order.submittedSuccessfully');
        this.toastr.success(msg);
        this.router.navigate(['/order']);
      },
      error: () => {
        const msg = this.translocoService.translate('order.errorWhileSubmitting');
        this.toastr.error(msg);
      },
    });
  };
}
