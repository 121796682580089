import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  inject,
  input,
  output,
  signal,
} from '@angular/core';

import { AxpoButtonComponent } from '../axpo-button/axpo-button.component';
import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';

@Component({
  selector: 'axpo-dialog',
  standalone: true,
  imports: [AxpoButtonComponent, AxpoTypographyComponent, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-dialog.component.html',
  styleUrl: './axpo-dialog.component.css',
  animations: [
    trigger('foreground', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' })),
      ]),
    ]),
    trigger('background', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AxpoDialogComponent implements OnInit, OnDestroy {
  title = input<string>();
  icon = input<'warning' | undefined>();
  confirmText = input.required<string>();
  cancelText = input<string>();
  textAlign = input<'center' | 'left'>('center');
  takenAction = output<'confirm' | 'cancel'>();
  displayDialog = signal<boolean>(true);

  private renderer = inject(Renderer2);

  takeAction = (action: 'confirm' | 'cancel') => {
    this.takenAction.emit(action);
    this.displayDialog.set(false);
  };

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'overflow-hidden');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }
}
