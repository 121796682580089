import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  effect,
  inject,
  untracked,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { BreadcrumbService } from '../../../shared/services/breadcrumb.service';
import { ContractManagementComponent } from '../contract-management/contract-management.component';
import { CustomerManagementService } from '../customer-management.service';
import { TrailerManagementComponent } from '../trailer-management/trailer-management.component';

@Component({
  selector: 'app-customer-overview',
  standalone: true,
  imports: [
    ContractManagementComponent,
    TrailerManagementComponent,
    AxpoTypographyComponent,
    AxpoButtonComponent,
    TranslocoPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './customer-overview.component.html',
  styleUrl: './customer-overview.component.css',
})
export class CustomerOverviewComponent implements OnDestroy {
  public customerManagementService = inject(CustomerManagementService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private breadcrumbService = inject(BreadcrumbService);

  constructor() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.customerManagementService.loadCustomer(params.customerId);
    });
  }

  _customerEffect = effect(() => {
    const customer = this.customerManagementService.customer();
    untracked(() => {
      if (customer)
        this.breadcrumbService.replaceBreadcrumbs({ [customer.id]: customer.companyName });
    });
  });

  onCustomerEditClick = () => {
    if (this.customerManagementService.customer()) {
      this.router.navigate([
        'customer-management',
        this.customerManagementService.customer()!.id,
        'edit',
      ]);
    }
  };

  ngOnDestroy() {
    this.customerManagementService.customer.set(undefined);
  }
}
