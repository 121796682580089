@if (!orderService.contractDetails() && orderService.orderId()) {
  <div class="flex justify-center mt-20">
    <axpo-spinner />
  </div>
} @else {
  <div class="flex flex-col items-center">
    @if (showCustomerSelection()) {
      <div class="my-3 w-96">
        <axpo-form-element
          formType="select"
          [label]="'customer.Customer' | transloco"
          [(value)]="orderService.selectedCustomerId"
          [options]="customerSelectOptions()"
          [disabled]="customerSelectOptions().length === 0 || !!orderService.orderId()"
        />
      </div>
    }

    <div class="my-3 w-96">
      <axpo-form-element
        formType="select"
        [label]="'order.Location' | transloco"
        [(value)]="orderService.selectedPlantId"
        [options]="plantSelectOptions()"
        [disabled]="plantSelectOptions().length === 0 || !!orderService.orderId()"
      />
    </div>

    <div class="my-3 w-96">
      <axpo-form-element
        formType="select"
        [label]="'order.YourContract' | transloco"
        [(value)]="orderService.selectedContractId"
        [options]="contractSelectOptions()"
        [disabled]="contractSelectOptions().length === 0"
      />
      @if (orderService.contractDetails(); as details) {
        <div class="my-1">
          <axpo-typography variant="body-s">
            {{ 'order.TotalContractVolume' | transloco }}:
            @if (orderService.selectedContractId()) {
              {{ orderService.contractDetails()?.totalAmount }}

              kg, {{ 'order.PickUp' | transloco }}:
              {{ orderService.contractDetails()?.orderedAmount }} kg ,
              {{ 'order.RemainingVolume' | transloco }}:
              {{ orderService.contractDetails()?.availableAmount }} kg
            } @else {
              {{ null }}
            }
          </axpo-typography>
        </div>
      }

      <div class="my-3 w-96">
        <axpo-form-element
          formType="number"
          [label]="'order.HydrogenVolumeForThisOrder' | transloco"
          [(value)]="orderService.selectedAmount"
          [min]="orderService.contractDetails()?.minAmountPerOrder"
          [max]="orderService.maxAmountPerOrder()"
        />
        @if (orderService.selectedAmountInM3()) {
          <div class="my-1">
            <axpo-typography variant="body-s">
              = {{ orderService.selectedAmountInM3() }} m&#179;
            </axpo-typography>
          </div>
        }
        @if (orderService.contractDetails(); as details) {
          <div class="my-1">
            <axpo-typography variant="body-s">
              {{ 'order.MinAmount' | transloco }}:
              {{ orderService.contractDetails()!.minAmountPerOrder }} kg,
              {{ 'order.MaxAmount' | transloco }}: {{ orderService.maxAmountPerOrder() }} kg
            </axpo-typography>
          </div>
        }
      </div>

      <div class="my-3 w-96">
        <axpo-form-element
          formType="select"
          [label]="'order.YourConnectorType' | transloco"
          [(value)]="orderService.selectedConnectorType"
          [options]="connectorTypeSelectOptions()"
          [disabled]="connectorTypeSelectOptions().length === 0"
        />
      </div>

      <div class="my-3 w-96">
        <axpo-form-element
          formType="select"
          [label]="'order.YourTrailer' | transloco"
          [(value)]="orderService.selectedTrailerId"
          [options]="trailerSelectOptions()"
          [disabled]="trailerSelectOptions().length === 0"
        />
      </div>

      <div class="my-3">
        <axpo-button type="ghost" (click)="cancel()">{{ 'core.Cancel' | transloco }}</axpo-button>
        <axpo-button
          type="filled"
          [disabled]="!orderService.orderDetailsValid()"
          (click)="continue()"
        >
          {{ 'order.ContinueToCollectionTime' | transloco }}
        </axpo-button>
      </div>
    </div>
  </div>
}
