import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';

export interface IconsProps {
  text: string;
  path: string;
  link: string;
  target: 'external_link' | 'internal_link';
  alt?: string;
}

@Component({
  selector: 'axpo-icon-gallery',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterModule, CommonModule, AxpoTypographyComponent],
  templateUrl: './axpo-icon-gallery.component.html',
  styleUrl: './axpo-icon-gallery.component.css',
})
export class AxpoIconGalleryComponent {
  icons = input<IconsProps[]>([]);
}
