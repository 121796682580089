import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ISort } from '../../core/axpo-table/axpo-table.component';
import {
  IContract,
  IContractFull,
  ICustomer,
  IPage,
  IPlant,
  ITrailer,
} from '../../shared/models/api_models';
import { ApiService } from '../../shared/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerManagementService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  private router = inject(Router);

  isLoading = signal<boolean>(false);
  customers = signal<IPage<ICustomer>>({ count: 0, data: [] });
  customer = signal<ICustomer | undefined>(undefined);
  trailers = signal<IPage<ITrailer>>({ count: 0, data: [] });
  trailer = signal<ITrailer | undefined>(undefined);
  contracts = signal<IPage<IContract>>({ count: 0, data: [] });
  contract = signal<IContractFull | undefined>(undefined);
  availableConnectorTypes = signal<string[]>([]);
  availablePlants = signal<IPlant[]>([]);

  public loadCustomers = (
    page: number,
    pageSize: number,
    sortBy: ISort,
    active: boolean,
    searchValue: string | null,
  ) => {
    if (this.customers().count == 0) this.isLoading.set(true);
    let apiLink = '';
    if (searchValue) {
      apiLink += `&searchTerm=${searchValue}`;
    }
    this.apiService
      .get<
        IPage<ICustomer>
      >(`api/v1/company?currentPage=${page}&pageSize=${pageSize}&orderBy=${sortBy.field}&sortDirection=${sortBy.direction}&onlyActive=${active}${apiLink}`)
      .subscribe({
        next: response => {
          this.customers.set(response);
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('customer.FailedToFetchCustomer'));
        },
        complete: () => {
          this.isLoading.set(false);
        },
      });
  };

  public loadTrailers = (page: number, pageSize: number, customerId: string, active: boolean) => {
    if (this.trailers().count == 0) this.isLoading.set(true);
    this.apiService
      .get<
        IPage<ITrailer>
      >(`api/v1/trailer?companyId=${customerId}&currentPage=${page}&pageSize=${pageSize}&onlyActive=${active}`)
      .subscribe({
        next: response => {
          this.trailers.set(response);
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('customer.FailedToFetchTrailer'));
        },
        complete: () => {
          this.isLoading.set(false);
        },
      });
  };

  public loadContracts = (page: number, pageSize: number, customerId: string, active: boolean) => {
    if (this.contracts().count == 0) this.isLoading.set(true);
    this.apiService
      .get<
        IPage<IContract>
      >(`api/v1/contract?companyId=${customerId}&currentPage=${page}&pageSize=${pageSize}&onlyActive=${active}`)
      .subscribe({
        next: response => {
          this.contracts.set(response);
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('customer.FailedToFetchContract'));
        },
        complete: () => {
          this.isLoading.set(false);
        },
      });
  };

  public loadConnectorTypes = () => {
    this.apiService.get<string[]>('api/v1/filling_bay/types/').subscribe({
      next: response => {
        this.availableConnectorTypes.set(response);
      },
      error: () => {
        this.toastr.error(this.translocoService.translate('customer.FailedToFetchConnectorTypes'));
      },
    });
  };

  public loadPlants = () => {
    this.apiService.get<IPage<IPlant>>('api/v1/plant').subscribe({
      next: response => {
        this.availablePlants.set(response.data);
      },
      error: () => {
        this.toastr.error(this.translocoService.translate('customer.FailedToFetchPlants'));
      },
    });
  };

  public loadCustomer = (customerId: string) => {
    this.apiService.get<ICustomer>(`api/v1/company/${customerId}`).subscribe({
      next: customer => {
        this.customer.set(customer);
      },
      error: () => {
        const msg = this.translocoService.translate('customer.FailedToFetchCustomer');
        this.toastr.error(msg);
      },
    });
  };

  public loadTrailer = (trailerId: string) => {
    this.apiService.get<ITrailer>(`api/v1/trailer/${trailerId}`).subscribe({
      next: trailer => {
        this.trailer.set(trailer);
      },
      error: () => {
        const msg = this.translocoService.translate('customer.FailedToFetchTrailer');
        this.toastr.error(msg);
      },
    });
  };

  public loadContract = (contractId: string) => {
    this.apiService.get<IContractFull>(`api/v1/contract/full/${contractId}`).subscribe({
      next: contract => {
        this.contract.set(contract);
      },
      error: () => {
        const msg = this.translocoService.translate('customer.FailedToFetchContract');
        this.toastr.error(msg);
      },
    });
  };

  public createCustomer = (
    customerName: string,
    isActive: boolean,
    contactPerson: string,
    contactEmail: string,
    contactPhone: string,
    logo: string,
  ) => {
    this.apiService
      .post('api/v1/company', {
        body: {
          companyName: customerName,
          isActive: isActive,
          contactPerson: contactPerson,
          contactEmail: contactEmail,
          contactPhone: contactPhone,
          logo: logo,
        },
      })
      .subscribe({
        next: () => {
          this.toastr.success(this.translocoService.translate('customer.CustomerCreated'));
          this.router.navigate(['/customer-management']);
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('customer.FailedToCreateCustomer'));
        },
      });
  };

  public updateCustomer = (
    customerId: string,
    customerName: string,
    isActive: boolean,
    contactPerson: string,
    contactEmail: string,
    contactPhone: string,
    logo: string,
  ) => {
    this.apiService
      .patch(`api/v1/company/${customerId}`, {
        body: {
          companyName: customerName,
          isActive: isActive,
          contactPerson: contactPerson,
          contactEmail: contactEmail,
          contactPhone: contactPhone,
          logo: logo,
        },
      })
      .subscribe({
        next: () => {
          this.toastr.success(this.translocoService.translate('customer.CustomerEdited'));
          this.router.navigate(['/customer-management', customerId]);
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('customer.FailedToEditCustomer'));
        },
      });
  };

  public createTrailer = (
    trailerName: string,
    isActive: boolean,
    capacity: number,
    connectorTypesAdd: string[],
    customerId: string,
  ) => {
    this.apiService
      .post('api/v1/trailer', {
        body: {
          trailerName: trailerName,
          isActive: isActive,
          capacity: capacity,
          connectorTypesAdd: connectorTypesAdd,
          companyId: customerId,
        },
      })
      .subscribe({
        next: () => {
          this.toastr.success(this.translocoService.translate('customer.TrailerCreated'));
          this.router.navigate(['/customer-management', customerId]);
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('customer.FailedToCreateTrailer'));
        },
      });
  };

  public updateTrailer = (
    trailerId: string,
    trailerName: string,
    isActive: boolean,
    capacity: number,
    connectorTypesAdd: string[],
    connectorTypesRemove: string[],
    customerId: string,
  ) => {
    this.apiService
      .patch(`api/v1/trailer/${trailerId}`, {
        body: {
          trailerName: trailerName,
          isActive: isActive,
          capacity: capacity,
          connectorTypesAdd: connectorTypesAdd,
          connectorTypesRemove: connectorTypesRemove,
        },
      })
      .subscribe({
        next: () => {
          this.toastr.success(this.translocoService.translate('customer.TrailerEdited'));
          this.router.navigate(['/customer-management', customerId]);
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('customer.FailedToEditTrailer'));
        },
      });
  };

  public createContract = (
    customerId: string,
    contractName: string,
    plantIds: string[],
    isActive: boolean,
    start: string,
    end: string,
    totalAmount: number,
    pricePerKg: number,
    minAmountPerOrder: number,
    maxAmountPerOrder: number,
    orderFreezeInH: number,
    pickUpBufferInH: number,
    endurContractId: string,
  ) => {
    this.apiService
      .post('api/v1/contract', {
        body: {
          contractName: contractName,
          companyId: customerId,
          plantIds: plantIds,
          isActive: isActive,
          start: start,
          end: end,
          totalAmount: totalAmount,
          pricePerKg: pricePerKg,
          minAmountPerOrder: minAmountPerOrder,
          maxAmountPerOrder: maxAmountPerOrder,
          orderFreezeInH: orderFreezeInH,
          pickUpBufferInH: pickUpBufferInH,
          endurContractId: endurContractId,
        },
      })
      .subscribe({
        next: () => {
          this.toastr.success(this.translocoService.translate('customer.ContractCreated'));
          this.router.navigate(['/customer-management', customerId]);
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('customer.FailedToCreateContract'));
        },
      });
  };

  public updateContract = (
    contractId: string,
    customerId: string,
    contractName: string,
    plantIdAdd: string[],
    plantIdRemove: string[],
    isActive: boolean,
    start: string,
    end: string,
    totalAmount: number,
    pricePerKg: number,
    minAmountPerOrder: number,
    maxAmountPerOrder: number,
    orderFreezeInH: number,
    pickUpBufferInH: number,
    endurContractId: string,
  ) => {
    this.apiService
      .patch(`api/v1/contract/${contractId}`, {
        body: {
          contractName: contractName,
          plantAdd: plantIdAdd,
          plantRemove: plantIdRemove,
          isActive: isActive,
          start: start,
          end: end,
          totalAmount: totalAmount,
          pricePerKg: pricePerKg,
          minAmountPerOrder: minAmountPerOrder,
          maxAmountPerOrder: maxAmountPerOrder,
          orderFreezeInH: orderFreezeInH,
          pickUpBufferInH: pickUpBufferInH,
          endurContractId: endurContractId,
        },
      })
      .subscribe({
        next: () => {
          this.toastr.success(this.translocoService.translate('customer.ContractEdited'));
          this.router.navigate(['/customer-management', customerId]);
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('customer.FailedToEditContract'));
        },
      });
  };

  public resetSelections = () => {
    this.customer.set(undefined);
    this.customers.set({ count: 0, data: [] });
    this.trailer.set(undefined);
    this.trailers.set({ count: 0, data: [] });
    this.contract.set(undefined);
    this.contracts.set({ count: 0, data: [] });
  };
}
