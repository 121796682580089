<header>
  <div class="flex px-4 py-2 align-middle border-b">
    <div class="w-1/3">
      <axpo-typography [variant]="'title-xl'">
        <a
          routerLink="/"
          class="flex items-center align-middle text-text-primary hover:text-primary"
        >
          <div class="block w-[35px] overflow-hidden relative">
            <axpo-logo />
          </div>
          <div class="hidden md:block ps-2 whitespace-nowrap">{{ title() }}</div>
        </a>
      </axpo-typography>
    </div>
    <div class="flex items-center justify-center w-1/3">
      <axpo-breadcrumb [separator]="separator()"></axpo-breadcrumb>
    </div>
    <div class="flex justify-end w-1/3 gap-3">
      @if (feedback()) {
        <button type="button" class="w-8" (click)="handleFeedbackClick($event)">
          <img src="images/feedback.svg" alt="feedback" class="w-full h-full" />
        </button>
      }
      <axpo-profile-dropdown
        class="text-primary"
        [availableLanguages]="availableLanguages()"
        [(selectedLanguageCode)]="selectedLanguageCode"
        [profileImage]="profileImage()"
        [user]="user()"
        [showLogout]="showLogout()"
        (onLogout)="onHeaderLogout()"
        [customActions]="customActions()"
        (onCustomAction)="onCustomHeaderAction($event.value, $event.subvalue)"
      ></axpo-profile-dropdown>
    </div>
  </div>
</header>
