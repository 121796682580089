import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  computed,
  effect,
  inject,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { debounceSet } from '../../../shared/utils/debounce';
import { isEmail } from '../../../shared/utils/email';
import { isPhoneNumber } from '../../../shared/utils/phone';
import { CustomerManagementService } from '../customer-management.service';
const MAX_LOGO_SIZE = 100;

@Component({
  selector: 'app-add-edit-customer',
  standalone: true,
  imports: [AxpoFormElementComponent, AxpoButtonComponent, AxpoTypographyComponent, TranslocoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './add-edit-customer.component.html',
  styleUrl: './add-edit-customer.component.css',
})
export class AddEditCustomerComponent implements OnDestroy {
  private router = inject(Router);
  private customerManagementService = inject(CustomerManagementService);
  private activatedRoute = inject(ActivatedRoute);

  canvasRef = viewChild<ElementRef>('canvas');

  customerId = signal<string | undefined>(undefined);
  customerName = signal<string | undefined>(undefined);
  isActive = signal<boolean>(true);
  contactPerson = signal<string | undefined>(undefined);
  contactEmail = signal<string | undefined>(undefined);
  contactPhone = signal<string | undefined>(undefined);
  logo = signal<File | undefined>(undefined);
  logoBase64 = signal<string | undefined>(undefined);
  emailIsValid = signal<boolean>(true);
  phoneIsValid = signal<boolean>(true);
  logoIsValid = signal<boolean>(true);
  logoLoad = signal<boolean>(false);

  constructor() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.customerId) {
        this.customerId.set(params.customerId);
        this.customerManagementService.loadCustomer(params.customerId);
      }
    });
  }

  _customerEffect = effect(() => {
    const customer = this.customerManagementService.customer();
    untracked(() => {
      if (!customer) return;
      this.customerName.set(customer.companyName);
      this.isActive.set(customer.isActive);
      this.contactPerson.set(customer.contactPerson);
      this.contactEmail.set(customer.contactEmail);
      this.contactPhone.set(customer.contactPhone);
      this.drawResizedImage(customer.logo);
    });
  });

  _emailValidationEffect = effect(() => {
    const email = this.contactEmail();
    untracked(() => {
      if (email) {
        debounceSet(isEmail(email), 500, this.emailIsValid);
      }
    });
  });

  _phoneValidationEffect = effect(() => {
    const phone = this.contactPhone();
    untracked(() => {
      if (phone) {
        debounceSet(isPhoneNumber(phone), 500, this.phoneIsValid);
      }
    });
  });

  _logoEffect = effect(() => {
    const logo = this.logo();
    untracked(() => {
      if (!logo) {
        this.resetLogo();
      } else if (logo.type.match(/image.*/)) {
        const reader = new FileReader();
        reader.onload = readerEvent => {
          if (readerEvent.target?.result)
            this.drawResizedImage(readerEvent.target.result as string);
        };
        reader.readAsDataURL(logo);
        this.logoLoad.set(true);
      } else {
        this.logoBase64.set(undefined);
        this.logoIsValid.set(false);
      }
    });
  });

  resetLogo = () => {
    this.logoBase64.set(undefined);
    this.logoIsValid.set(true);
    this.canvasRef()!.nativeElement.getContext('2d').reset();
  };

  drawResizedImage = (base64: string) => {
    const image = new Image();
    image.onload = () => {
      let width = image.width;
      let height = image.height;
      if (width >= height && width >= MAX_LOGO_SIZE) {
        height *= MAX_LOGO_SIZE / width;
        width = MAX_LOGO_SIZE;
      } else if (height > width && height > MAX_LOGO_SIZE) {
        width *= MAX_LOGO_SIZE / height;
        height = MAX_LOGO_SIZE;
      }
      const canvasRef = this.canvasRef();
      if (!canvasRef) return;
      canvasRef.nativeElement.width = width;
      canvasRef.nativeElement.height = height;
      const context = canvasRef.nativeElement.getContext('2d', { alpha: false });
      context.fillStyle = '#FFFFFF';
      context.fillRect(0, 0, width, height);
      context.drawImage(image, 0, 0, width, height);
      const dataUrl = canvasRef.nativeElement.toDataURL('image/jpeg');
      this.logoBase64.set(dataUrl);
      this.logoIsValid.set(true);
    };
    image.src = base64;
  };

  isFormValid = computed(() => {
    return (
      (this.customerName() !== this.customerManagementService.customer()?.companyName ||
        this.contactPerson() !== this.customerManagementService.customer()?.contactPerson ||
        this.contactEmail() !== this.customerManagementService.customer()?.contactEmail ||
        this.isActive() !== this.customerManagementService.customer()?.isActive ||
        this.logoLoad()) &&
      this.customerName() &&
      this.contactPerson() &&
      this.contactEmail() &&
      this.contactPhone() &&
      this.emailIsValid() &&
      this.phoneIsValid() &&
      this.logoBase64()
    );
  });

  submit = () => {
    if (this.isFormValid()) {
      if (this.customerId()) {
        this.customerManagementService.updateCustomer(
          this.customerId()!,
          this.customerName()!,
          this.isActive(),
          this.contactPerson()!,
          this.contactEmail()!,
          this.contactPhone()!,
          this.logoBase64()!,
        );
      } else {
        this.customerManagementService.createCustomer(
          this.customerName()!,
          this.isActive(),
          this.contactPerson()!,
          this.contactEmail()!,
          this.contactPhone()!,
          this.logoBase64()!,
        );
      }
    }
  };

  cancel = () => {
    this.router.navigate(['/customer-management']);
  };

  ngOnDestroy() {
    this.customerManagementService.resetSelections();
  }
}
