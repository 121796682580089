import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../services/user-data.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  private userService = inject(UserService);
  private router = inject(Router);

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const expectedRoles: string[] = next.data['roles'];

    return new Observable<boolean>(observer => {
      const subscription = this.userService.fetchUserData().subscribe({
        next: _user => {
          const rolesSignal = this.userService.getUserRoles();
          const roles = rolesSignal();
          if (expectedRoles.includes('all')) {
            observer.next(true);
          } else if (roles && roles.length > 0) {
            const userRoles = roles.map(role => role.role);
            const hasRole = expectedRoles.some(role => userRoles.includes(role));

            if (!hasRole) {
              this.router.navigate(['unauthorized'], { skipLocationChange: true });
              observer.next(false);
            } else {
              observer.next(true);
            }
          } else {
            this.router.navigate(['unauthorized'], { skipLocationChange: true });
            observer.next(false);
          }

          observer.complete();
        },
        error: () => {
          this.router.navigate(['unauthorized'], { skipLocationChange: true });
          observer.next(false);
          observer.complete();
        },
      });

      return {
        unsubscribe() {
          subscription.unsubscribe();
        },
      };
    });
  }
}
