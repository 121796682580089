<div class="container py-4 mx-auto w-[830px]">
  <axpo-typography variant="title-xl">
    {{
      'plantManagement.EditFillingBayTitle'
        | transloco: { filling_bay: plantManagementService.fillingBay().insightsId }
    }}
  </axpo-typography>
  @if (this.plantManagementService.isLoading()) {
    <div class="mt-16">
      <axpo-spinner>{{ 'core.Loading' | transloco }}</axpo-spinner>
    </div>
  } @else {
    <div class="w-32 my-3">
      <axpo-form-element
        formType="text"
        [label]="'plantManagement.FillingBayNumber' | transloco"
        [(value)]="fillingBayNumber"
        [min]="0"
      />
    </div>

    <div class="my-3">
      <axpo-form-element
        formType="checkbox"
        [label]="'plantManagement.IsOperating' | transloco"
        [(value)]="IsOperating"
      />
    </div>

    <div class="flex justify-end gap-3 my-10">
      <axpo-button (click)="cancel()" type="ghost" [style]="'secondary'">
        {{ 'core.Cancel' | transloco }}
      </axpo-button>

      <axpo-button (click)="submit()" [disabled]="!isFormValid()">
        {{ 'plantManagement.EditFillingBay' | transloco }}
      </axpo-button>
    </div>

    <axpo-typography variant="title-l">
      {{ 'plantManagement.MaintenanceWindows' | transloco }}
    </axpo-typography>
    @for (window of plantManagementService.maintenanceWindows(); track window.id) {
      @if (window.fillingBayInsightsId === plantManagementService.fillingBay().insightsId) {
        <app-edit-maintenance-windows
          [window]="window"
          [fillingBayInsightsId]="plantManagementService.fillingBay().insightsId"
        />
      }
    }
    <app-edit-maintenance-windows
      [fillingBayInsightsId]="plantManagementService.fillingBay().insightsId"
    />
  }
</div>
