import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  model,
  signal,
  untracked,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { UserManagementService } from './user-management.service';
import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import {
  AxpoDropdownComponent,
  IDropdownItem,
} from '../../core/axpo-dropdown/axpo-dropdown.component';
import { AxpoSearchComponent } from '../../core/axpo-search/axpo-search.component';
import {
  AxpoTableComponent,
  IActionClick,
  ISort,
  ITableColumn,
  ITableRow,
} from '../../core/axpo-table/axpo-table.component';
import { AxpoTableNavigationComponent } from '../../core/axpo-tablenavigation/axpo-tablenavigation.component';
import { AxpoTagComponent } from '../../core/axpo-tag/axpo-tag.component';
import { LanguageService } from '../../shared/services/lang.service';
import { UserService } from '../../shared/services/user-data.service';
import { debounceSet } from '../../shared/utils/debounce';

@Component({
  standalone: true,
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AxpoTableComponent,
    AxpoTableNavigationComponent,
    AxpoTagComponent,
    TranslocoPipe,
    AxpoButtonComponent,
    AxpoDropdownComponent,
    FormsModule,
    AxpoSearchComponent,
  ],
})
export class UserManagementComponent {
  private translocoService = inject(TranslocoService);
  private router = inject(Router);
  userManagementService = inject(UserManagementService);
  private langService = inject(LanguageService);
  private userService = inject(UserService);
  private toastrService = inject(ToastrService);

  page = signal<number>(1);
  pageSize = signal<number>(10);
  sort = signal<ISort>({ field: 'createdAt', direction: 'desc' });
  columns = signal<ITableColumn[]>([]);
  selectedRole = signal<string | null>(null);

  searchValue = model<string | undefined>();
  debouncedSearchValue = signal<string | undefined>('');

  // UserList
  _userListApi = effect(() => {
    const page = this.page();
    const pageSize = this.pageSize();
    const sort = this.sort();
    const searchValue = this.searchValue() || null;
    const selectedRole = this.selectedRole();
    untracked(() => {
      this.userManagementService.loadUsersList(page, pageSize, sort, searchValue, selectedRole);
    });
  });

  userListData = computed<ITableRow[]>(() => {
    return this.userManagementService.usersList().data.map(user => {
      return {
        id: { value: user.id },
        companyName: {
          imageValue: user.companyLogo,
          value: user.companyName,
        },
        userName: {
          bold_value:
            user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email,
          value: user.firstName && user.lastName ? user.email : '',
        },
        createdAt: { value: user.createdAt },
        status: { value: user.status || 'active' },
        roles: {
          valueArray: user.roles.map(role => role.role),
        },
      };
    });
  });

  // UserRoles
  _onInit = effect(() => {
    untracked(() => {
      this.userManagementService.getRoles();
    });
  });

  userRoles = computed<IDropdownItem[]>(() => {
    const roles = this.userManagementService.userRolesForSelect();
    const allOption: IDropdownItem = {
      label: this.translocoService.translate('userManagement.AllRoles'),
      value: '',
    };

    return [allOption, ...roles];
  });

  // Interaction actions
  onAddUserClick(): void {
    this.router.navigate(['user-management/new']);
  }

  onSearchValueChange(value: string | undefined): void {
    debounceSet<string | undefined>(value, 500, this.searchValue);
  }

  onRoleSelect(value: string): void {
    if (value === '') {
      this.selectedRole.set(null);
    } else {
      this.selectedRole.set(value);
    }
  }

  // Table
  _updateColumnsEffect = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    untracked(() => {
      this.columns.set(this.getColumns());
    });
  });

  totalItems = computed<number>(() => this.userManagementService.usersList()?.count || 0);

  onSortClick = ($event: ISort) => {
    this.sort.set($event);
  };

  onActionClick = ($event: IActionClick) => {
    if ($event.action === 'edit') {
      const userRoles = this.userService
        .getUserRoles()()
        ?.map(role => role.role);
      if (
        $event.row.roles.valueArray?.includes('operator_admin') &&
        !userRoles?.includes('operator_admin')
      ) {
        const msg = this.translocoService.translate('userManagement.CannotEditOperatorAdmin');
        this.toastrService.error(msg);
      } else {
        this.router.navigate([`user-management/${$event.row['id'].value}`]);
      }
    }
  };

  getColumns = (): ITableColumn[] => {
    return [
      {
        title: this.translocoService.translate('userManagement.Company'),
        field: 'companyName',
        formatter: ['image'],
      },
      {
        title: this.translocoService.translate('userManagement.Users'),
        field: 'userName',
        sortable: true,
      },
      {
        title: this.translocoService.translate('userManagement.Joined'),
        field: 'createdAt',
        sortable: true,
        formatter: ['date'],
      },
      {
        title: this.translocoService.translate('userManagement.Status'),
        field: 'status',
      },
      {
        title: this.translocoService.translate('userManagement.AllRoles'),
        field: 'roles',
        formatter: ['roleTag'],
      },
    ];
  };
}
