<div class="w-full flex items-center" role="tablist">
  @for (step of steps(); track step; let index = $index; let last = $last) {
    <button
      class="inline-flex flex-wrap items-center justify-center p-5 text-text-primary text-center whitespace-nowrap"
      role="tab"
      (click)="activeStep.set(index)"
      [ngClass]="{
        'pointer-events-none': activeStep() <= index,
        'hover:bg-background-2': activeStep() > index,
      }"
    >
      <span
        class="content-center justify-center w-8 h-8 m-1 rounded-full text-primary border border-primary"
        [ngClass]="{
          'bg-primary text-white': activeStep() === index,
        }"
      >
        {{ index + 1 }}
      </span>
      <span class="m-1">{{ step }}</span>
    </button>
    @if (!last) {
      <div class="flex-grow bg-border-soft h-px"></div>
    }
  }
</div>
