<div class="w-96 mx-auto py-4">
  <axpo-typography variant="title-xl">
    @if (trailerId()) {
      {{ 'customer.EditTrailer' | transloco }}
    } @else {
      {{ 'customer.AddTrailer' | transloco }}
    }
  </axpo-typography>
  <div class="my-3 w-96">
    <axpo-form-element
      formType="text"
      [label]="'customer.TrailerName' | transloco"
      [(value)]="trailerName"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="number"
      [label]="'customer.CapacityInKg' | transloco"
      [(value)]="capacity"
      [min]="0"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="multiselect"
      [label]="'customer.ConnectorType' | transloco"
      [options]="availableConnectorTypes()"
      [(value)]="connectorTypes"
      [disabled]="availableConnectorTypes().length === 0"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="checkbox"
      [label]="'customer.IsActive' | transloco"
      [(value)]="isActive"
    />
  </div>

  <div class="flex justify-end gap-3 mb-3">
    <axpo-button (click)="cancel()" type="ghost" [style]="'secondary'">
      {{ 'core.Cancel' | transloco }}
    </axpo-button>

    <axpo-button (click)="submit()" [disabled]="!isFormValid()">
      @if (trailerId()) {
        {{ 'customer.EditTrailer' | transloco }}
      } @else {
        {{ 'customer.AddTrailer' | transloco }}
      }
    </axpo-button>
  </div>
</div>
