<div class="container px-2 mx-auto">
  <div class="flex mt-24"></div>
  <div class="mt-20">
    <axpo-table
      [data]="this.plantListData()"
      [columns]="columns()"
      [actions]="['view']"
      [headerType]="'ghost'"
      [isLoading]="plantManagementService.isLoading()"
      (onActionClick)="onActionClick($event)"
    />
    <axpo-tablenavigation
      [(page)]="page"
      [(pageSize)]="pageSize"
      [totalItems]="totalItems()"
      [style]="'default'"
      [itemsPerPage]="'core.itemsPerPage' | transloco"
    />
  </div>
</div>
