<div class="py-4 mx-auto w-96">
  <axpo-typography variant="title-xl">
    @if (contractId()) {
      {{ 'customer.EditContract' | transloco }}
    } @else {
      {{ 'customer.AddContract' | transloco }}
    }
  </axpo-typography>
  <div class="my-3 w-96">
    <axpo-form-element
      formType="text"
      [label]="'customer.ContractName' | transloco"
      [(value)]="contractName"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="multiselect"
      [label]="'customer.Plant' | transloco"
      [options]="plants()"
      [(value)]="plantIds"
      [disabled]="plants().length === 0"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="date"
      [label]="'customer.StartDate' | transloco"
      [(value)]="start"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element formType="date" [label]="'customer.EndDate' | transloco" [(value)]="end" />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="number"
      [label]="'customer.TotalContractVolume' | transloco"
      [(value)]="totalAmount"
      [min]="0"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="number"
      [label]="'customer.ContractPrice' | transloco"
      [(value)]="pricePerKg"
      [min]="0"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="number"
      [label]="'customer.MinAmountPerOrder' | transloco"
      [(value)]="minAmountPerOrder"
      [min]="0"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="number"
      [label]="'customer.MaxAmountPerOrder' | transloco"
      [(value)]="maxAmountPerOrder"
      [min]="0"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="number"
      [label]="'customer.PickUpBufferInH' | transloco"
      [(value)]="pickUpBufferInH"
      [min]="0"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="number"
      [label]="'customer.OrderFreezeInH' | transloco"
      [(value)]="orderFreezeInH"
      [min]="0"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="text"
      [label]="'customer.EndurContractId' | transloco"
      [(value)]="endurContractId"
    />
  </div>

  <div class="my-3 w-96">
    <axpo-form-element
      formType="checkbox"
      [label]="'customer.IsActive' | transloco"
      [(value)]="isActive"
    />
  </div>

  <div class="flex justify-end gap-3 mb-3">
    <axpo-button (click)="cancel()" type="ghost" [style]="'secondary'">
      {{ 'core.Cancel' | transloco }}
    </axpo-button>

    <axpo-button (click)="submit()" [disabled]="!isFormValid()">
      @if (contractId()) {
        {{ 'customer.EditContract' | transloco }}
      } @else {
        {{ 'customer.AddContract' | transloco }}
      }
    </axpo-button>
  </div>
</div>
