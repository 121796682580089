<div class="my-8 flex gap-2 items-end justify-between">
  <div class="flex gap-2">
    <axpo-form-element
      formType="date"
      [label]="'plantManagement.StartDate' | transloco"
      [(value)]="maintenanceStartDate"
    />
    <axpo-form-element
      formType="time"
      [label]="'plantManagement.StartTime' | transloco"
      [(value)]="maintenanceStartTime"
    />
    <axpo-form-element
      formType="date"
      [label]="'plantManagement.EndDate' | transloco"
      [(value)]="maintenanceEndDate"
    />
    <axpo-form-element
      formType="time"
      [label]="'plantManagement.EndTime' | transloco"
      [(value)]="maintenanceEndTime"
    />
  </div>
  <div class="flex gap-2">
    <axpo-button (click)="save()" [disabled]="!isFormValid()">
      @if (window()) {
        {{ 'core.Edit' | transloco }}
      } @else {
        {{ 'core.Save' | transloco }}
      }
    </axpo-button>
    @if (window()) {
      <axpo-button (click)="delete()">
        {{ 'core.Delete' | transloco }}
      </axpo-button>
    }
  </div>
</div>
