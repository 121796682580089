import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
  inject,
} from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
// eslint-disable-next-line
import SwaggerUI from 'swagger-ui';

import { ApiService } from '../../shared/services/api.service';

@Component({
  selector: 'app-swagger-ui',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './swagger-ui.component.html',
  styleUrl: './swagger-ui.component.css',
})
export class SwaggerUiComponent implements AfterContentInit {
  apiService = inject(ApiService);
  oidcSecurityService = inject(OidcSecurityService);
  toastr = inject(ToastrService);

  @ViewChild('apidocumentation', { static: true }) apidocumentation: ElementRef | undefined;

  ngAfterContentInit(): void {
    this.loadSwaggerUI();
  }

  loadSwaggerUI = async () => {
    this.apiService.get<any>('openapi.json').subscribe({
      next: openapi => {
        SwaggerUI({
          spec: openapi,
          domNode: this.apidocumentation?.nativeElement,
          oauth2RedirectUrl: `${window.location.origin}/login`,
          requestInterceptor: async req => {
            const accessToken = await firstValueFrom(this.oidcSecurityService.getAccessToken());
            req['headers'].Authorization = `Bearer ${accessToken}`;
            return req;
          },
        });
      },
      error: () => {
        this.toastr.error('Failed to load OpenAPI specification from Backend');
      },
    });
  };
}
