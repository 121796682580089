import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  effect,
  inject,
  output,
  untracked,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoSpinnerComponent } from '../../../core/axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { UserService } from '../../../shared/services/user-data.service';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-order-details',
  standalone: true,
  imports: [
    AxpoTypographyComponent,
    AxpoButtonComponent,
    AxpoFormElementComponent,
    FormsModule,
    TranslocoPipe,
    AxpoSpinnerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './order-details.component.html',
  styleUrl: './order-details.component.css',
})
export class OrderDetailsComponent implements OnInit {
  private router = inject(Router);
  public orderService = inject(OrderService);
  private userService = inject(UserService);
  nextStep = output<void>();

  ngOnInit() {
    this.orderService.loadPlants();
  }

  _selectedCustomer = effect(() => {
    const userSignal = this.userService.getUserData();
    const user = userSignal();
    const userRoles = this.userService.getUserRoles()();
    untracked(() => {
      if (
        user &&
        userRoles &&
        !['operator', 'operator_admin'].some(role => userRoles.map(el => el.role).includes(role))
      ) {
        this.orderService.selectedCustomerId.set(user.companyId);
      }
    });
  });

  showCustomerSelection = computed(() => {
    const isOperator = this.userService.isOperator();
    return isOperator;
  });

  operatorEffect = effect(() => {
    if (this.showCustomerSelection()) {
      this.orderService.loadCustomers();
    }
  });

  customerSelectOptions = computed(() => {
    return this.orderService
      .customers()
      .map(customer => ({ value: customer.id, label: customer.companyName }))
      .sort((a, b) => a.label.localeCompare(b.label));
  });

  plantSelectOptions = computed(() =>
    this.orderService
      .plants()
      .map(plant => ({ value: plant.id, label: plant.name }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  );
  contractSelectOptions = computed(() =>
    this.orderService
      .contracts()
      .map(contract => ({ value: contract.id, label: contract.contractName }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  );
  connectorTypeSelectOptions = computed(() =>
    this.orderService
      .connectorTypes()
      .map(connectorType => ({ value: connectorType, label: connectorType }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  );
  trailerSelectOptions = computed(() =>
    this.orderService
      .trailers()
      .map(trailer => ({ value: trailer.id, label: trailer.trailerName }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  );

  cancel = () => {
    this.router.navigate(['/order']);
  };

  continue = () => {
    this.nextStep.emit();
  };
}
