import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { IOption } from '../../core/axpo-form-element/axpo-form-element.component';
import { ISort } from '../../core/axpo-table/axpo-table.component';
import { ICustomer, IPage, IRole, IUser } from '../../shared/models/api_models';
import { FormatTextPipe } from '../../shared/pipes/format-text.pipe';
import { ApiService } from '../../shared/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  private router = inject(Router);
  formatTextPipe = new FormatTextPipe();

  customers = signal<ICustomer[]>([]);
  user = signal<IUser | null>(null);
  availableCustomers = signal<IOption[]>([]);
  isLoading = signal<boolean>(false);
  usersList = signal<IPage<IUser>>({ count: 0, data: [] });
  userRoles = signal<IRole[]>([]);
  userRolesForSelect = computed(() => {
    const roles = this.userRoles()
      .map(role => {
        return {
          label: this.formatTextPipe.transform(role.role),
          value: role.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    return roles;
  });

  public loadUsersList = (
    page: number,
    pageSize: number,
    sortBy: ISort,
    searchValue: string | null,
    selectedRole: string | null,
  ) => {
    if (this.usersList().count == 0) this.isLoading.set(true);
    let apiLink = '';
    if (searchValue) {
      apiLink += `&searchTerm=${searchValue}`;
    }
    if (selectedRole) {
      apiLink += `&rolesToFilter=${selectedRole}`;
    }
    this.apiService
      .get<
        IPage<IUser>
      >(`api/v1/user?currentPage=${page}&pageSize=${pageSize}&orderBy=${sortBy.field}&sortDirection=${sortBy.direction}${apiLink}`)
      .subscribe({
        next: list => {
          this.usersList.set(list);
        },
        error: () =>
          this.toastr.error(this.translocoService.translate('userManagement.FailedToFetchUser')),
        complete: () => {
          this.isLoading.set(false);
        },
      });
  };

  public getRoles = () => {
    this.apiService.get<IRole[]>(`api/v1/role`).subscribe({
      next: roles => {
        this.userRoles.set(roles);
      },
      error: () => {
        const msg = this.translocoService.translate('userManagement.FailedToFetchRoles');
        this.toastr.error(msg);
      },
    });
  };

  public loadCustomers = () => {
    this.apiService.get<IPage<ICustomer>>(`api/v1/company?onlyActive=true`).subscribe({
      next: response => {
        this.customers.set(response.data);
        this.availableCustomers.set(
          response.data.map(company => ({ value: company.id, label: company.companyName })),
        );
      },
      error: () => {
        this.toastr.error(this.translocoService.translate('customer.FailedToFetchCustomer'));
      },
    });
  };

  public loadUser = (userId: string) => {
    this.apiService.get<IUser>(`api/v1/user/${userId}`).subscribe({
      next: user => {
        this.user.set(user);
      },
      error: () => {
        const msg = this.translocoService.translate('userManagement.FailedToFetchUser');
        this.toastr.error(msg);
      },
    });
  };

  public createUser = (email: string, roles: string[], companyId: string) => {
    const body: Record<string, string | string[] | boolean> = {
      email: email,
      rolesAdd: roles,
      isServiceAccount: false,
      companyId: companyId,
    };
    this.apiService
      .post('api/v1/user', {
        body,
      })
      .subscribe({
        next: () => {
          const msg = this.translocoService.translate('userManagement.UserCreated');
          this.toastr.success(msg);
          this.router.navigate(['/user-management']);
        },
        error: (error: HttpErrorResponse) => {
          if (error.error.detail.code === 470) {
            this.toastr.error(this.translocoService.translate('userManagement.UserAlreadyExists'));
          } else {
            this.toastr.error(this.translocoService.translate('userManagement.FailedToCreateUser'));
          }
        },
      });
  };

  public updateUser = (
    userId: string,
    rolesAdd: string[],
    rolesRemove: string[],
    companyId?: string,
  ) => {
    const body: Record<string, string | string[]> = {
      rolesAdd,
      rolesRemove,
    };
    if (companyId) {
      body['companyId'] = companyId;
    }

    this.apiService
      .patch(`api/v1/user/${userId}`, {
        body,
      })
      .subscribe({
        next: () => {
          const msg = this.translocoService.translate('userManagement.UserEdited');
          this.toastr.success(msg);
          this.router.navigate(['/user-management']);
        },
        error: () => {
          const msg = this.translocoService.translate('userManagement.FailedToEditUser');
          this.toastr.error(msg);
        },
      });
  };

  public deleteUser = (userId: string) => {
    this.apiService.delete(`api/v1/user/${userId}`).subscribe({
      next: () => {
        const msg = this.translocoService.translate('userManagement.UserDeleted');
        this.toastr.success(msg);
        this.router.navigate(['/user-management']);
      },
      error: () => {
        const msg = this.translocoService.translate('userManagement.FailedToDeleteUser');
        this.toastr.error(msg);
      },
    });
  };
}
