import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoDialogComponent } from '../../../core/axpo-dialog/axpo-dialog.component';
import {
  AxpoTableComponent,
  IActionClick,
  ITableColumn,
  ITableRow,
} from '../../../core/axpo-table/axpo-table.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { LanguageService } from '../../../shared/services/lang.service';
import { formatTime, weekdayOrder } from '../../../shared/utils/formatTime';
import { PlantManagementService } from '../plant-management.service';

@Component({
  standalone: true,
  selector: 'app-edit-plant',
  templateUrl: './edit-plant.component.html',
  styleUrl: './edit-plant.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AxpoTypographyComponent,
    AxpoTableComponent,
    AxpoDialogComponent,
    TranslocoPipe,
    DatePipe,
  ],
})
export class EditPlantComponent implements OnDestroy {
  private translocoService = inject(TranslocoService);
  private router = inject(Router);
  private langService = inject(LanguageService);
  public plantManagementService = inject(PlantManagementService);
  private activatedRoute = inject(ActivatedRoute);

  columnsOperatingHours = signal<ITableColumn[]>([]);
  columnsFillingBays = signal<ITableColumn[]>([]);

  // OperatingHoursList
  _operatingHoursListApi = effect(() => {
    const plantId = this.activatedRoute.snapshot.paramMap.get('id');
    untracked(() => {
      if (plantId) {
        this.plantManagementService.loadOperatingHours(plantId);
        this.plantManagementService.loadFillingBays(plantId);
      }
    });
  });

  operatingHourData = computed<ITableRow[]>(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    const sortedData = this.plantManagementService.operatingHours().sort((a, b) => {
      return (weekdayOrder[a.day] || 0) - (weekdayOrder[b.day] || 0);
    });
    return sortedData.map(day => {
      return {
        id: { value: day.id },
        weekday: { value: this.translocoService.translate(`plantManagement.${day.day}`) },
        from: { value: formatTime(day.startTime) },
        until: { value: formatTime(day.endTime) },
        operating: {
          value: day.operating ? 'operational' : 'unavailable',
          tagTranslation: day.operating
            ? this.translocoService.translate(`tags.operational`)
            : this.translocoService.translate(`tags.unavailable`),
        },
      };
    });
  });

  fillingBayData = computed<ITableRow[]>(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    const sortedData = this.plantManagementService.fillingBays();

    return sortedData.map(fillingBay => {
      return {
        id: { value: fillingBay.id },
        number: { value: fillingBay.number ? fillingBay.number : '-' },
        connectors: { value: `${fillingBay.connectorType} (${fillingBay.insightsId})` },
        active: {
          value: fillingBay.isActive ? 'operational' : 'unavailable',
          tagTranslation: fillingBay.isActive
            ? this.translocoService.translate(`tags.operational`)
            : this.translocoService.translate(`tags.unavailable`),
        },
      };
    });
  });

  // Table
  _updateColumnsEffect = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    untracked(() => {
      this.columnsOperatingHours.set(this.getColumnsOperatingHours());
      this.columnsFillingBays.set(this.getColumnsFillingBays());
    });
  });

  onActionOperatingHoursClick = ($event: IActionClick) => {
    if ($event.action === 'edit') {
      this.router.navigate([
        `plant-management/${this.plantManagementService.operatingHours()[0].plantId}/opening-hours/${$event.row['id'].value}`,
      ]);
    }
  };

  onActionFillingBayClick = ($event: IActionClick) => {
    if ($event.action === 'edit') {
      this.router.navigate([
        `plant-management/${this.plantManagementService.operatingHours()[0].plantId}/filling-bay/${$event.row['id'].value}`,
      ]);
    }
  };

  getColumnsOperatingHours = (): ITableColumn[] => {
    return [
      {
        title: this.translocoService.translate('plantManagement.Weekday'),
        field: 'weekday',
      },
      {
        title: this.translocoService.translate('plantManagement.Operating'),
        field: 'operating',
        formatter: ['tag'],
      },
      {
        title: this.translocoService.translate('plantManagement.From'),
        field: 'from',
      },
      {
        title: this.translocoService.translate('plantManagement.Until'),
        field: 'until',
      },
    ];
  };

  getColumnsFillingBays = (): ITableColumn[] => {
    return [
      {
        title: this.translocoService.translate('plantManagement.Number'),
        field: 'number',
      },
      {
        title: this.translocoService.translate('plantManagement.Connectors'),
        field: 'connectors',
      },
      {
        title: this.translocoService.translate('plantManagement.Active'),
        field: 'active',
        formatter: ['tag'],
      },
    ];
  };

  ngOnDestroy(): void {
    this.plantManagementService.operatingHours.set([]);
    this.plantManagementService.fillingBays.set([]);
    this.plantManagementService.maintenanceWindows.set([]);
  }
}
