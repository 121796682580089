import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { PlantManagementService } from './plant-management.service';
import {
  AxpoTableComponent,
  IActionClick,
  ISort,
  ITableColumn,
  ITableRow,
} from '../../core/axpo-table/axpo-table.component';
import { AxpoTableNavigationComponent } from '../../core/axpo-tablenavigation/axpo-tablenavigation.component';
import { LanguageService } from '../../shared/services/lang.service';

@Component({
  standalone: true,
  selector: 'app-plant-management',
  templateUrl: './plant-management.component.html',
  styleUrl: './plant-management.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AxpoTableComponent, AxpoTableNavigationComponent, TranslocoPipe],
})
export class PlantManagementComponent {
  private translocoService = inject(TranslocoService);
  private router = inject(Router);
  private langService = inject(LanguageService);
  public plantManagementService = inject(PlantManagementService);

  page = signal<number>(1);
  pageSize = signal<number>(10);
  sort = signal<ISort>({ field: 'createdAt', direction: 'desc' });
  columns = signal<ITableColumn[]>([]);

  // PlantList
  _plantListApi = effect(() => {
    const page = this.page();
    const pageSize = this.pageSize();
    untracked(() => {
      this.plantManagementService.loadPlants(page, pageSize);
    });
  });

  plantListData = computed<ITableRow[]>(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    return this.plantManagementService.plants().data.map(plant => {
      return {
        id: { value: plant.id },
        plantName: { bold_value: plant.name, link: `/plant-management/${plant.id}` },
        fillingBays: { value: plant.totalFillingBays.toString() },
        status: {
          value: plant.status === 'operational' ? plant.status : 'unavailable',
          tagTranslation: this.translocoService.translate(
            plant.status === 'operational' ? `tags.${plant.status}` : 'tags.unavailable',
          ),
        },
      };
    });
  });

  // Table
  _updateColumnsEffect = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    untracked(() => {
      this.columns.set(this.getColumns());
    });
  });

  totalItems = computed<number>(() => this.plantManagementService.plants()?.count || 0);

  onActionClick = ($event: IActionClick) => {
    if ($event.action === 'view') {
      this.router.navigate([`plant-management/${$event.row['id'].value}`]);
    }
  };

  getColumns = (): ITableColumn[] => {
    return [
      {
        title: this.translocoService.translate('plantManagement.PlantName'),
        field: 'plantName',
        formatter: ['internal_link'],
      },
      {
        title: this.translocoService.translate('plantManagement.FillingBays'),
        field: 'fillingBays',
      },
      {
        title: this.translocoService.translate('plantManagement.Status'),
        field: 'status',
        formatter: ['tag'],
      },
    ];
  };
}
