import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoDropdownComponent } from '../../../core/axpo-dropdown/axpo-dropdown.component';
import {
  AxpoTableComponent,
  IActionClick,
  ITableColumn,
  ITableRow,
} from '../../../core/axpo-table/axpo-table.component';
import { AxpoTableNavigationComponent } from '../../../core/axpo-tablenavigation/axpo-tablenavigation.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { LanguageService } from '../../../shared/services/lang.service';
import { CustomerManagementService } from '../customer-management.service';

@Component({
  standalone: true,
  selector: 'app-trailer-management',
  templateUrl: './trailer-management.component.html',
  styleUrl: './trailer-management.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AxpoTableComponent,
    AxpoTableNavigationComponent,
    TranslocoPipe,
    AxpoButtonComponent,
    AxpoDropdownComponent,
    FormsModule,
    AxpoTypographyComponent,
  ],
})
export class TrailerManagementComponent implements OnDestroy {
  private translocoService = inject(TranslocoService);
  private router = inject(Router);
  private langService = inject(LanguageService);
  public customerManagementService = inject(CustomerManagementService);

  page = signal<number>(1);
  pageSize = signal<number>(10);
  columns = signal<ITableColumn[]>([]);
  onlyActiveTrailers = signal<boolean>(false);
  customerId = input.required<string>();

  // Trailer List
  _trailerApi = effect(() => {
    const page = this.page();
    const pageSize = this.pageSize();
    const customerId = this.customerId();
    const active = this.onlyActiveTrailers();
    untracked(() => {
      this.customerManagementService.loadTrailers(page, pageSize, customerId, active);
    });
  });

  trailerListData = computed<ITableRow[]>(() => {
    return this.customerManagementService.trailers().data.map(trailer => {
      return {
        id: { value: trailer.id },
        trailerName: { value: trailer.trailerName },
        capacity: { value: trailer.capacity.toString() },
        connector: { value: trailer.connectorTypes.join(', ') },
        status: {
          value: trailer.isActive
            ? this.translocoService.translate('customer.Active')
            : this.translocoService.translate('customer.Inactive'),
        },
      };
    });
  });

  // Table
  _updateColumnsEffect = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    untracked(() => {
      this.columns.set(this.getColumns());
    });
  });

  _filterEffect = effect(() => {
    const _active = this.onlyActiveTrailers();
    untracked(() => {
      this.page.set(1);
    });
  });

  totalItems = computed<number>(() => this.customerManagementService.trailers()?.count || 0);

  // Interaction actions
  onAddTrailerClick(): void {
    this.router.navigate(['customer-management', this.customerId(), 'trailer', 'new']);
  }

  onSelectActiveTrailers = (value: string) => {
    this.onlyActiveTrailers.set(value === 'active');
  };

  onActionClick = ($event: IActionClick) => {
    if ($event.action === 'edit') {
      this.router.navigate([
        'customer-management',
        this.customerId(),
        'trailer',
        $event.row['id'].value,
      ]);
    }
  };

  getColumns = (): ITableColumn[] => {
    return [
      {
        title: this.translocoService.translate('customer.TrailerName'),
        field: 'trailerName',
      },
      {
        title: this.translocoService.translate('customer.TrailerCapacity'),
        field: 'capacity',
      },
      {
        title: this.translocoService.translate('customer.TrailerConnector'),
        field: 'connector',
      },
      {
        title: this.translocoService.translate('customer.Status'),
        field: 'status',
      },
    ];
  };

  ngOnDestroy(): void {
    this.customerManagementService.trailers.set({ count: 0, data: [] });
  }
}
