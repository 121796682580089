import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoSpinnerComponent } from '../../../../core/axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../../../../core/axpo-typography/axpo-typography.component';
import { IFillingBay } from '../../../../shared/models/api_models';
import { PlantManagementService } from '../../plant-management.service';
import { EditMaintenanceWindowsComponent } from '../edit-maintenance-windows/edit-maintenance-windows.component';

@Component({
  standalone: true,
  selector: 'app-edit-filling-bays',
  templateUrl: './edit-filling-bays.component.html',
  styleUrl: './edit-filling-bays.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AxpoTypographyComponent,
    AxpoFormElementComponent,
    AxpoButtonComponent,
    TranslocoPipe,
    AxpoSpinnerComponent,
    EditMaintenanceWindowsComponent,
  ],
})
export class EditFillingBaysComponent implements OnDestroy {
  private router = inject(Router);
  public plantManagementService = inject(PlantManagementService);
  private activatedRoute = inject(ActivatedRoute);

  fillingBayNumber = signal<string | undefined>(undefined);
  IsOperating = signal<boolean>(true);
  fillingBayId = signal<string | undefined>(undefined);
  plantId = signal<string | undefined>(undefined);

  _onInit = effect(
    () => {
      const routeParam = this.activatedRoute.snapshot.paramMap.get('id');
      const plantId = this.activatedRoute.snapshot.paramMap.get('plantId');
      if (routeParam && plantId && routeParam !== this.fillingBayId()) {
        this.fillingBayId.set(routeParam);
        this.plantId.set(plantId);
        this.plantManagementService.loadFillingBay(routeParam);
        this.plantManagementService.loadMaintenanceWindows(plantId);
      }
    },
    { allowSignalWrites: true },
  );

  _fillingBayDataEffect = effect(() => {
    const fillingBay = this.plantManagementService.fillingBay();
    untracked(() => {
      if (Object.keys(fillingBay).length === 0) return;
      this.fillingBayNumber.set(fillingBay.number ? fillingBay.number : undefined);
      this.IsOperating.set(fillingBay.isActive);
    });
  });

  cancel = () => {
    this.router.navigate([`/plant-management/${this.plantId()}`]);
  };

  isFormValid = computed(() => {
    const fillingBayNumberValue = this.fillingBayNumber();
    return (
      (fillingBayNumberValue !== this.plantManagementService.fillingBay().number ||
        this.IsOperating() !== this.plantManagementService.fillingBay().isActive) &&
      fillingBayNumberValue !== undefined
    );
  });

  submit = () => {
    if (this.isFormValid() && this.fillingBayId() && this.plantId()) {
      this.plantManagementService.updateFillingBay(
        this.fillingBayId()!,
        this.IsOperating(),
        this.fillingBayNumber()!,
        this.plantId()!,
      );
    }
  };

  ngOnDestroy(): void {
    this.fillingBayNumber.set(undefined);
    this.IsOperating.set(true);
    this.fillingBayId.set('');
    this.plantId.set('');
    this.plantManagementService.fillingBay.set({} as IFillingBay);
  }
}
