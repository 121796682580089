import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoTypographyComponent } from '../../../../core/axpo-typography/axpo-typography.component';
import { IMaintenanceWindow } from '../../../../shared/models/api_models';
import { PlantManagementService } from '../../plant-management.service';

@Component({
  selector: 'app-edit-maintenance-windows',
  standalone: true,
  imports: [AxpoTypographyComponent, AxpoButtonComponent, AxpoFormElementComponent, TranslocoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './edit-maintenance-windows.component.html',
  styleUrl: './edit-maintenance-windows.component.css',
})
export class EditMaintenanceWindowsComponent {
  today = new Date().toJSON().split('T')[0];
  window = input<IMaintenanceWindow | undefined>(undefined);
  fillingBayInsightsId = input.required<string>();
  private plantManagementService = inject(PlantManagementService);

  edited = signal<boolean>(false);
  maintenanceStartDate = signal<string | undefined>(undefined);
  maintenanceEndDate = signal<string | undefined>(undefined);
  maintenanceStartTime = signal<string | undefined>(undefined);
  maintenanceEndTime = signal<string | undefined>(undefined);

  _onInit = effect(() => {
    const window = this.window();
    untracked(() => {
      if (window) {
        this.maintenanceStartDate.set(window.start.split('T')[0]);
        this.maintenanceEndDate.set(window.end.split('T')[0]);
        this.maintenanceStartTime.set(window.start.split('T')[1]);
        this.maintenanceEndTime.set(window.end.split('T')[1]);
      }
    });
  });

  _edited = effect(() => {
    const maintenanceStartDate = this.maintenanceStartDate();
    const maintenanceEndDate = this.maintenanceEndDate();
    const maintenanceStartTime = this.maintenanceStartTime();
    const maintenanceEndTime = this.maintenanceEndTime();
    const window = this.window();
    untracked(() => {
      if (!window) return;
      if (
        maintenanceStartDate !== window.start.split('T')[0] ||
        maintenanceEndDate !== window.end.split('T')[0] ||
        maintenanceStartTime !== window.start.split('T')[1] ||
        maintenanceEndTime !== window.end.split('T')[1]
      ) {
        this.edited.set(true);
      } else {
        this.edited.set(false);
      }
    });
  });

  isFormValid = computed(() => {
    return (
      this.maintenanceStartDate() &&
      this.maintenanceEndDate() &&
      this.maintenanceStartTime() &&
      this.maintenanceEndTime() &&
      (this.maintenanceStartDate()! < this.maintenanceEndDate()! ||
        (this.maintenanceStartDate() === this.maintenanceEndDate() &&
          this.maintenanceStartTime()! < this.maintenanceEndTime()!)) &&
      this.maintenanceStartDate()! >= this.today &&
      (this.edited() || !this.window())
    );
  });

  save = () => {
    const start = `${this.maintenanceStartDate()}T${this.maintenanceStartTime()}`;
    const end = `${this.maintenanceEndDate()}T${this.maintenanceEndTime()}`;
    if (this.window()?.id) {
      this.plantManagementService.updateMaintenanceWindow(start, end, this.window()!.id);
    } else {
      this.plantManagementService.createMaintenanceWindow(start, end, this.fillingBayInsightsId());
      this.clear();
    }
  };

  clear = () => {
    this.maintenanceStartDate.set(undefined);
    this.maintenanceEndDate.set(undefined);
    this.maintenanceStartTime.set(undefined);
    this.maintenanceEndTime.set(undefined);
  };

  delete = () => {
    this.plantManagementService.deleteMaintenanceWindow(this.window()!.id);
  };
}
