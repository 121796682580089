<axpo-typography variant="title-l">{{ 'order.ReviewYourOrder' | transloco }}</axpo-typography>

<div class="flex flex-col items-center">
  <div class="p-3 m-3 text-left border border-border-soft">
    <axpo-typography variant="title-l" weight="bold">
      {{ 'order.OrderSummary' | transloco }}
    </axpo-typography>
    <div class="flex my-1">
      <div class="w-56">
        <axpo-typography weight="bold">{{ 'order.Location' | transloco }}</axpo-typography>
      </div>
      <div>{{ selectedPlantName() }}</div>
    </div>
    <div class="flex my-1">
      <div class="w-56">
        <axpo-typography weight="bold">{{ 'order.Contract' | transloco }}</axpo-typography>
      </div>
      <div>{{ selectedContractName() }}</div>
    </div>
    <div class="flex my-1">
      <div class="w-56">
        <axpo-typography weight="bold">{{ 'order.ConnectorType' | transloco }}</axpo-typography>
      </div>
      <div>{{ orderService.selectedConnectorType() }}</div>
    </div>
    <div class="flex my-1">
      <div class="w-56">
        <axpo-typography weight="bold">{{ 'order.Trailer' | transloco }}</axpo-typography>
      </div>
      <div>{{ selectedTrailerName() }}</div>
    </div>
    <div class="flex my-1">
      <div class="w-56">
        <axpo-typography weight="bold">
          {{ 'order.HydrogenVolume' | transloco }}
        </axpo-typography>
      </div>
      <div>{{ orderService.selectedAmount() }} kg</div>
    </div>
    <div class="flex my-1">
      <div class="w-56">
        <axpo-typography weight="bold">{{ 'order.DropOff' | transloco }}</axpo-typography>
      </div>
      <div>
        {{ orderService.selectedTimeSlot()?.dropOff! | localizedDate: 'EEEE, dd.MM.yyyy HH:mm' }}
      </div>
    </div>
    <div class="flex my-1">
      <div class="w-56">
        <axpo-typography weight="bold">{{ 'order.PickUp' | transloco }}</axpo-typography>
      </div>
      <div>
        {{ orderService.selectedTimeSlot()?.pickUp! | localizedDate: 'EEEE, dd.MM.yyyy HH:mm' }}
      </div>
    </div>
  </div>

  <div class="my-3">
    <axpo-button type="ghost" (click)="back()">
      {{ 'core.Back' | transloco }}
    </axpo-button>
    <axpo-button type="filled" (click)="submit()" [disabled]="!orderService.selectedTimeSlot()">
      {{ (orderService.orderId() ? 'order.EditOrder' : 'order.Order') | transloco }}
    </axpo-button>
  </div>
</div>
