import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from './api.service';

export interface RolesProps {
  id: string;
  role: string;
}

export interface UserProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  companyId: string;
  roles: RolesProps[];
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user = signal<UserProps | null>(null);
  private roles = signal<RolesProps[] | null>(null);
  isOperator = signal<boolean>(false);
  private apiService = inject(ApiService);
  private router = inject(Router);

  fetchUserData(): Observable<UserProps> {
    if (this.user()) {
      return of(this.user() as UserProps);
    }
    return this.apiService.get<UserProps>('/api/v1/user/userinfo').pipe(
      tap(user => {
        this.user.set(user);
        this.roles.set(user.roles);
        this.isOperator.set(
          user.roles.some(role => role.role === 'operator' || role.role === 'operator_admin'),
        );
        if (user.roles.length === 0) {
          this.router.navigate(['/unauthorized'], { skipLocationChange: true });
        }
      }),
    );
  }

  getUserData() {
    return this.user;
  }

  getUserRoles() {
    return this.roles;
  }
}
