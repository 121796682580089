import { Route } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

import { LoginComponent } from './core/login/login.component';
import { AddEditContractComponent } from './features/customer-management/add-edit-contract/add-edit-contract.component';
import { AddEditCustomerComponent } from './features/customer-management/add-edit-customer/add-edit-customer.component';
import { AddEditTrailerComponent } from './features/customer-management/add-edit-trailer/add-edit-trailer.component';
import { CustomerManagementComponent } from './features/customer-management/customer-management.component';
import { CustomerOverviewComponent } from './features/customer-management/customer-overview/customer-overview.component';
import { HomeComponent } from './features/home/home.component';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { EditOrderComponent } from './features/order/edit-order/edit-order.component';
import { NewOrderComponent } from './features/order/new-order/new-order.component';
import { OrderListComponent } from './features/order-list/order-list.component';
import { EditFillingBaysComponent } from './features/plant-management/edit-plant/edit-filling-bays/edit-filling-bays.component';
import { EditOpeningHoursComponent } from './features/plant-management/edit-plant/edit-opening-hours/edit-opening-hours.component';
import { EditPlantComponent } from './features/plant-management/edit-plant/edit-plant.component';
import { PlantManagementComponent } from './features/plant-management/plant-management.component';
import { SwaggerUiComponent } from './features/swagger-ui/swagger-ui.component';
import { UnauthorizedComponent } from './features/unauthorized/unauthorized.component';
import { AddEditUserComponent } from './features/user-management/add-edit-user/add-edit-user.component';
import { UserManagementComponent } from './features/user-management/user-management.component';
import { RoleGuard } from './shared/guards/role.guard';

export const ROUTES: Route[] = [
  {
    path: 'plant-management/:plantId/opening-hours',
    redirectTo: 'plant-management/:plantId',
    pathMatch: 'full',
  },
  {
    path: 'plant-management/:plantId/filling-bay',
    redirectTo: 'plant-management/:plantId',
    pathMatch: 'full',
  },
  {
    path: 'customer-management/:customerId/contract',
    redirectTo: 'customer-management/:customerId',
    pathMatch: 'full',
  },
  {
    path: 'customer-management/:customerId/trailer',
    redirectTo: 'customer-management/:customerId',
    pathMatch: 'full',
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent,
    title: 'Green Gas Customer Portal',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['all'] },
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Green Gas Customer Portal',
  },
  {
    path: 'order/new',
    component: NewOrderComponent,
    title: 'Green Gas Customer Portal: New Order',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['orderer', 'customer_admin', 'operator', 'operator_admin'] },
  },

  {
    path: 'order/:id',
    component: EditOrderComponent,
    title: 'Green Gas Customer Portal: Edit Order',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['orderer', 'customer_admin', 'operator', 'operator_admin'] },
  },
  {
    path: 'order',
    component: OrderListComponent,
    title: 'Green Gas Customer Portal - Orders List',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['driver', 'orderer', 'customer_admin', 'operator', 'operator_admin'] },
  },

  {
    path: 'user-management',
    component: UserManagementComponent,
    title: 'Green Gas Customer Portal - User Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['customer_admin', 'operator', 'operator_admin'] },
  },
  {
    path: 'user-management/new',
    component: AddEditUserComponent,
    title: 'Green Gas Customer Portal - User Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['customer_admin', 'operator', 'operator_admin'] },
  },
  {
    path: 'user-management/:id',
    component: AddEditUserComponent,
    title: 'Green Gas Customer Portal - User Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['customer_admin', 'operator', 'operator_admin'] },
  },
  {
    path: 'customer-management',
    component: CustomerManagementComponent,
    title: 'Green Gas Customer Portal - Customer Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['operator', 'operator_admin'] },
  },
  {
    path: 'customer-management/new',
    component: AddEditCustomerComponent,
    title: 'Green Gas Customer Portal - Customer Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['operator', 'operator_admin'] },
  },
  {
    path: 'customer-management/:customerId',
    component: CustomerOverviewComponent,
    title: 'Green Gas Customer Portal - Customer Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['operator', 'operator_admin'] },
  },

  {
    path: 'customer-management/:customerId/contract/new',
    component: AddEditContractComponent,
    title: 'Green Gas Customer Portal - Customer Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['operator', 'operator_admin'] },
  },
  {
    path: 'customer-management/:customerId/contract/:id',
    component: AddEditContractComponent,
    title: 'Green Gas Customer Portal - Customer Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['operator', 'operator_admin'] },
  },
  {
    path: 'customer-management/:customerId/trailer/new',
    component: AddEditTrailerComponent,
    title: 'Green Gas Customer Portal - Customer Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['operator', 'operator_admin'] },
  },
  {
    path: 'customer-management/:customerId/trailer/:id',
    component: AddEditTrailerComponent,
    title: 'Green Gas Customer Portal - Customer Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['operator', 'operator_admin'] },
  },
  {
    path: 'customer-management/:customerId/edit',
    component: AddEditCustomerComponent,
    title: 'Green Gas Customer Portal - Customer Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['operator', 'operator_admin'] },
  },
  {
    path: 'plant-management',
    component: PlantManagementComponent,
    title: 'Green Gas Customer Portal - Plant Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['operator', 'operator_admin'] },
  },
  {
    path: 'plant-management/:id',
    component: EditPlantComponent,
    title: 'Green Gas Customer Portal - Plant Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['operator', 'operator_admin'] },
  },
  {
    path: 'plant-management/:plantId/opening-hours/:id',
    component: EditOpeningHoursComponent,
    title: 'Green Gas Customer Portal - Plant Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['operator', 'operator_admin'] },
  },
  {
    path: 'plant-management/:plantId/filling-bay/:id',
    component: EditFillingBaysComponent,
    title: 'Green Gas Customer Portal - Plant Management',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: ['operator', 'operator_admin'] },
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    title: 'Green Gas Customer Portal - Unauthorized Access',
  },
  {
    path: '404',
    component: NotFoundComponent,
    title: 'Green Gas Customer Portal - Not Found',
  },
  {
    path: 'swagger',
    component: SwaggerUiComponent,
    title: 'Green Gas Customer Portal - Swagger UI',
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
    title: 'Green Gas Customer Portal - Not Found',
  },
];
