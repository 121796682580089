import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoDialogComponent } from '../../../core/axpo-dialog/axpo-dialog.component';
import { AxpoFormElementComponent } from '../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { BreadcrumbService } from '../../../shared/services/breadcrumb.service';
import { UserService } from '../../../shared/services/user-data.service';
import { debounceSet } from '../../../shared/utils/debounce';
import { isEmail } from '../../../shared/utils/email';
import { UserManagementService } from '../user-management.service';

@Component({
  selector: 'app-add-edit-user',
  standalone: true,
  imports: [
    AxpoTypographyComponent,
    AxpoButtonComponent,
    AxpoFormElementComponent,
    AxpoDialogComponent,
    TranslocoPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './add-edit-user.component.html',
  styleUrl: './add-edit-user.component.css',
})
export class AddEditUserComponent implements OnInit, OnDestroy {
  private userService = inject(UserService);
  public userManagementService = inject(UserManagementService);
  private activatedRoute = inject(ActivatedRoute);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  private router = inject(Router);
  private breadcrumbService = inject(BreadcrumbService);

  userId = signal<string | undefined>(undefined);
  email = signal<string | undefined>(undefined);
  companyId = signal<string | undefined>(undefined);
  selectedRoles = signal<string[]>([]);
  oldRoles: string[] = [];
  showDeleteDialog = signal<boolean>(false);
  emailIsValid = signal<boolean>(true);

  _paramsEffect = effect(() => {
    const routeParams = this.activatedRoute.snapshot.paramMap.get('id');
    if (routeParams) {
      untracked(() => {
        this.userId.set(routeParams);
      });
      this.userManagementService.loadUser(routeParams);
    }
  });

  _user = effect(() => {
    const user = this.userManagementService.user();
    if (user && this.userId()) {
      untracked(() => {
        const roles = user.roles.map(role => role.id);
        this.email.set(user.email);
        this.selectedRoles.set(roles);
        this.oldRoles = roles;
        this.companyId.set(user.companyId);
        this.breadcrumbService.replaceBreadcrumbs({ [user.id]: user.email });
      });
    }
  });

  ngOnInit(): void {
    if (this.userManagementService.userRoles().length === 0) this.userManagementService.getRoles();
  }

  _emailValidationEffect = effect(() => {
    const email = this.email();
    untracked(() => {
      if (email) {
        debounceSet(isEmail(email), 500, this.emailIsValid);
      }
    });
  });

  _companyLoadEffect = effect(() => {
    const user = this.userService.getUserData()();
    const showCompanySelection = this.showCompanySelection();
    untracked(() => {
      if (!showCompanySelection) {
        this.companyId.set(user?.companyId);
      }
    });
  });

  showCompanySelection = computed(() => {
    const userRoles = this.userService.getUserRoles()();
    if (
      userRoles &&
      ['operator', 'operator_admin'].some(role => userRoles.map(el => el.role).includes(role))
    ) {
      if (this.userManagementService.customers().length === 0)
        this.userManagementService.loadCustomers();
      return true;
    } else {
      return false;
    }
  });

  availableRoles = computed(() => {
    const userRoles = this.userService
      .getUserRoles()()
      ?.map(role => role.role);
    const allOperatorRoles = ['Operator', 'Operator Admin'];
    const accessibleOperatorRoles = userRoles?.includes('operator_admin')
      ? allOperatorRoles
      : ['Operator'];

    if (!this.companyId()) return [];

    const allRoles = this.userManagementService.userRolesForSelect();
    const customer = this.userManagementService
      .customers()
      .find(customer => customer.id === this.companyId());
    if (customer?.isOperator)
      return allRoles.filter(role => accessibleOperatorRoles.includes(role.label));
    else return allRoles.filter(role => !allOperatorRoles.includes(role.label));
  });

  isFormValid = computed(() => {
    return (
      (this.companyId() !== this.userManagementService.user()?.companyId ||
        this.oldRoles !== this.selectedRoles()) &&
      (this.email() || this.emailIsValid() || this.companyId() || this.selectedRoles().length === 0)
    );
  });

  createUser = () => {
    if (
      this.email() &&
      this.emailIsValid() &&
      this.selectedRoles().length > 0 &&
      this.companyId()
    ) {
      this.userManagementService.createUser(this.email()!, this.selectedRoles(), this.companyId()!);
    }
  };

  updateUser = () => {
    if (!this.userId()) return;
    const rolesToAdd = this.selectedRoles().filter(role => !this.oldRoles.includes(role));
    const rolesToRemove = this.oldRoles.filter(role => !this.selectedRoles().includes(role));
    this.userManagementService.updateUser(
      this.userId()!,
      rolesToAdd,
      rolesToRemove,
      this.companyId(),
    );
  };

  askToDeleteUser = () => {
    this.showDeleteDialog.set(true);
  };

  takeActionOnDeleteDialog = (action: 'confirm' | 'cancel') => {
    if (action === 'confirm') {
      this.deleteUser();
    }
    this.showDeleteDialog.set(false);
  };

  deleteUser = () => {
    if (!this.userId()) return;
    this.userManagementService.deleteUser(this.userId()!);
  };

  cancel = () => {
    this.router.navigate(['/user-management']);
  };

  ngOnDestroy(): void {
    this.userId.set(undefined);
    this.userManagementService.user.set(null);
  }
}
