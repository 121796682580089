import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { TranslocoPipe } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { OrderListService } from './order-list.service';
import { AxpoDialogComponent } from '../../core/axpo-dialog/axpo-dialog.component';
import {
  AxpoTableComponent,
  IActionClick,
  ISort,
  ITableColumn,
  ITableRow,
} from '../../core/axpo-table/axpo-table.component';
import { AxpoTableNavigationComponent } from '../../core/axpo-tablenavigation/axpo-tablenavigation.component';
import { LanguageService } from '../../shared/services/lang.service';
import { UserService } from '../../shared/services/user-data.service';
import { debounceSet } from '../../shared/utils/debounce';

@Component({
  standalone: true,
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrl: './order-list.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AxpoTableComponent, AxpoTableNavigationComponent, AxpoDialogComponent, TranslocoPipe],
})
export class OrderListComponent implements OnDestroy {
  private translocoService = inject(TranslocoService);
  private router = inject(Router);
  public orderListService = inject(OrderListService);
  private toastr = inject(ToastrService);
  private langService = inject(LanguageService);
  private userService = inject(UserService);

  page = signal<number>(1);
  pageSize = signal<number>(10);
  sort = signal<ISort>({ field: 'createdAt', direction: 'desc' });
  columns = signal<ITableColumn[]>([]);
  showDeleteDialog = signal<string | undefined>(undefined);

  _ = effect(() => {
    const page = this.page();
    const pageSize = this.pageSize();
    const sort = this.sort();
    untracked(() => {
      this.orderListService.loadOrdersList(page, pageSize, sort);
    });
  });

  _updateColumnsEffect = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    untracked(() => {
      this.columns.set(this.getColumns());
    });
  });

  listData = computed<ITableRow[]>(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    return (
      this.orderListService.orderList().data.map(order => ({
        id: { value: order.id },
        orderNumber: { value: order.orderNumber.toString() },
        createdAt: { value: order.createdAt },
        amount: { value: `${order.amount} kg` },
        bookedSlot: { value: `${order.dropOff}/${order.pickUp}` },
        location: { value: order.plantName || '' },
        fillingBayNumber: { value: order.fillingBayNumber?.toString() || '' },
        companyName: { value: order.companyName },
        userName: {
          value: order.firstName
            ? `${order.firstName} ${order.lastName}`
            : this.translocoService.translate('orderList.DeletedUser'),
        },
        status: {
          value: order.statusName,
          tagTranslation: this.translocoService.translate(`tags.${order.statusName}`),
        },
        actions: {
          valueArray:
            (order.statusName !== 'completed' && order.statusName !== 'collecting') ||
            this.userService.isOperator()
              ? ['edit', 'delete']
              : [],
        },
      })) || []
    );
  });

  totalItems = computed<number>(() => this.orderListService.orderList()?.count || 0);

  onSortClick = ($event: ISort) => {
    debounceSet<ISort>($event, 500, this.sort);
  };

  isOrderChangeable = (status?: string): boolean => {
    if (status === 'booked' || status == 'confirmed' || this.userService.isOperator()) return true;
    return false;
  };

  onActionClick = ($event: IActionClick) => {
    const status = $event.row['status'].value;
    if (this.isOrderChangeable(status)) {
      if ($event.action === 'delete') {
        this.showDeleteDialog.set($event.row['id'].value);
      } else if ($event.action === 'edit') {
        this.router.navigate([`/order/${$event.row['id'].value}`]);
      }
    } else {
      const msg = this.translocoService.translate('orderList.orderNotChangeable');
      this.toastr.error(msg);
    }
  };

  takeActionOnDeleteDialog = (action: string) => {
    if (action === 'confirm') {
      this.orderListService.deleteOrder(this.showDeleteDialog()!);
    }
    this.showDeleteDialog.set(undefined);
  };

  getColumns = (): ITableColumn[] => {
    return [
      {
        title: this.translocoService.translate('orderList.Order'),
        field: 'orderNumber',
        sortable: true,
      },
      {
        title: this.translocoService.translate('orderList.OrderDate'),
        field: 'createdAt',
        sortable: true,
        formatter: ['date'],
      },
      {
        title: this.translocoService.translate('orderList.Amount'),
        field: 'amount',
        sortable: true,
      },
      {
        title: this.translocoService.translate('orderList.BookedSlot'),
        field: 'bookedSlot',
        formatter: ['dateTime'],
      },
      {
        title: this.translocoService.translate('orderList.Location'),
        field: 'location',
      },
      {
        title: this.translocoService.translate('orderList.FillingBay'),
        field: 'fillingBayNumber',
      },
      {
        title: this.translocoService.translate('orderList.Customer'),
        field: 'companyName',
        sortable: true,
      },
      {
        title: this.translocoService.translate('orderList.User'),
        field: 'userName',
        sortable: true,
      },
      {
        title: this.translocoService.translate('orderList.Status'),
        field: 'status',
        sortable: true,
        formatter: ['tag'],
      },
      {
        title: '',
        field: 'actions',
        formatter: ['actions'],
      },
    ];
  };

  ngOnDestroy() {
    this.orderListService.resetOrderList();
  }
}
