<div class="my-3 w-96">
  <axpo-form-element
    formType="select"
    [label]="'order.YourTrailer' | transloco"
    [(value)]="orderService.selectedTrailerId"
    [options]="trailerSelectOptions()"
    [disabled]="trailerSelectOptions().length === 0"
  />

  <div class="my-3">
    <axpo-button type="ghost" (click)="cancel()">
      {{ 'core.Cancel' | transloco }}
    </axpo-button>
    <axpo-button
      type="filled"
      (click)="submit()"
      [disabled]="initialTrailerId() === orderService.selectedTrailerId()"
    >
      {{ (orderService.orderId() ? 'order.EditOrder' : 'order.Order') | transloco }}
    </axpo-button>
  </div>
</div>
