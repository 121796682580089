export function formatTime(time: string): string {
  const [formattedMinutes, _iso] = time.split('.');
  const [hours, minutes] = formattedMinutes.split(':');
  return `${hours}:${minutes}`;
}

export function formatTimeToISO(time: string | null): string {
  if (!time) return '';

  return `${time}:00.000Z`;
}

export const weekdayOrder: Record<string, number> = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export function sortByKeyNumber<T extends { number: number | null }>(items: T[]): T[] {
  return items.sort((a, b) => {
    if (a.number === null && b.number === null) return 0;
    if (a.number === null) return 1;
    if (b.number === null) return -1;

    return (a.number ?? 0) - (b.number ?? 0);
  });
}

export function formatMinutes(time: string): string {
  const [hours] = time.split(':');
  return `${hours}:00`;
}

export function subtractHour(timeString: string, subtract: number): string {
  // Create a new Date object for today's date, but with the provided hour and minute
  const [hours, minutes] = timeString.split(':').map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0); // Set the provided time (hours and minutes)

  // Subtract one hour
  date.setHours(date.getHours() - subtract);

  // Format the result back into "HH:mm"
  const newHours = String(date.getHours()).padStart(2, '0');
  const newMinutes = String(date.getMinutes()).padStart(2, '0');

  return `${newHours}:${newMinutes}`;
}
