@if (calendar(); as calendar) {
  <div class="flex items-stretch justify-center w-full">
    <div class="flex flex-col items-center">
      <table>
        <tr>
          <th>
            <button
              [disabled]="!previousWeekAvailable()"
              [ngClass]="{ 'text-light-gray': !previousWeekAvailable() }"
              (click)="changeWeek('previous')"
            >
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                stroke="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path d="M31.25 12.5L18.75 25L31.25 37.5" />
                </g>
              </svg>
            </button>
          </th>
          @for (day of weekdays; track day) {
            <th
              class="p-2 font-normal text-center border border-t-0 w-22 border-x-0 border-border-soft"
            >
              {{ datesOfSelectedWeek()[day] | localizedDate: 'EEE' }}
              <br />
              {{ datesOfSelectedWeek()[day] | date: 'dd.MM.yy' }}
            </th>
          }
          <th>
            <button
              [disabled]="!nextWeekAvailable()"
              [ngClass]="{ 'text-light-gray': !nextWeekAvailable() }"
              (click)="changeWeek('next')"
            >
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                stroke="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="matrix(-1,0,0,1,49.646447,0)">
                  <path d="M 31.25,12.5 18.75,25 31.25,37.5" />
                </g>
              </svg>
            </button>
          </th>
        </tr>
        @for (hour of hours; track hour; let isEven = $even) {
          <tr>
            @if (isEven) {
              <td class="h-12 px-2 text-center align-top" rowspan="2">
                {{ hoursString[hour] }}
              </td>
            }
            @for (day of weekdays; track day) {
              <td
                class="border border-border-soft"
                [ngClass]="{
                  'bg-secondary': calendar[day][hour] === 'unavailable',
                  'bg-tertiary': calendar[day][hour] === 'booked_by_same_customer',
                  'bg-light-yellow': ['selected', 'selected_but_unavailable'].includes(
                    calendar[day][hour]
                  ),
                  'cursor-not-allowed': [
                    'selected_but_unavailable',
                    'unavailable',
                    'booked_by_same_customer',
                  ].includes(calendar[day][hour]),
                  'hover:bg-background-1  cursor-pointer': ['available', 'selected'].includes(
                    calendar[day][hour]
                  ),
                  'dashed border-y-1': !isEven,
                  'border-y-0': isEven,
                }"
                (click)="onTimeSelect(day, hour)"
              ></td>
            }
          </tr>
        }
      </table>

      <div class="my-3">
        <axpo-button type="ghost" (click)="back()">{{ 'core.Back' | transloco }}</axpo-button>
        <axpo-button type="filled" (click)="continue()" [disabled]="disableContinueButton()">
          {{ 'order.ContinueToOrderSummary' | transloco }}
        </axpo-button>
      </div>
    </div>

    <div class="flex flex-col justify-between my-16">
      @if (orderService.selectedTimeSlot(); as timeSlot) {
        <div class="p-2 border border-border-soft">
          <axpo-typography variant="body-l" weight="bold">
            {{ 'order.YourSelection' | transloco }}:
          </axpo-typography>
          <div>
            <div class="flex">
              <div class="w-20">{{ 'order.DropOff' | transloco }}</div>
              <div>{{ timeSlot.dropOff | date: 'dd.MM.yyyy HH:mm' }}</div>
            </div>
            <div class="flex">
              <div class="w-20">{{ 'order.PickUp' | transloco }}</div>
              <div>{{ timeSlot.pickUp | date: 'dd.MM.yyyy HH:mm' }}</div>
            </div>
          </div>
        </div>
        <!-- TODO -->
        @if (maxDateForPickUp(); as maxDateForPickUp) {
          <div>
            <div class="p-2 border border-border-soft">
              <axpo-typography variant="body-l" weight="bold">
                {{ 'order.ExtendOrderPickUp' | transloco }}:
              </axpo-typography>
              <div class="mt-4">
                <axpo-form-element
                  formType="date"
                  [label]="'order.PickUpDay' | transloco"
                  [(value)]="dateForPickUp"
                  [max]="maxDateForPickUp.maxDate"
                ></axpo-form-element>
                <axpo-form-element
                  formType="time"
                  [label]="'order.PickUpTime' | transloco"
                  [(value)]="timeForPickUp"
                  [min]="openingHour()"
                  [max]="closingHour()"
                  [step]="3600"
                ></axpo-form-element>
              </div>
              <axpo-typography [variant]="'body-s'">
                {{ 'order.OrderCanBePick' | transloco }}
              </axpo-typography>

              @if (this.openingHour() && this.closingHour()) {
                <axpo-typography [variant]="'body-s'">
                  {{ this.openingHour()! | timeFormat }} - {{ this.closingHour()! | timeFormat }}
                </axpo-typography>
              } @else {
                <axpo-typography [variant]="'body-s'">
                  {{ 'order.Closed' | transloco }}
                </axpo-typography>
              }
            </div>
            <div class="pl-1">
              <axpo-typography [variant]="'body-s'">
                {{ 'order.maxTimeForPickUp' | transloco }}
              </axpo-typography>
              @if (maxDateForPickUp.maxDate !== 'Infinite' && maxDateForPickUp.maxDate !== 'None') {
                <axpo-typography [variant]="'body-s'">
                  {{
                    'order.dateTimePickUp'
                      | transloco
                        : {
                            day: maxDateForPickUp.maxDate | date: 'dd.MM.yyyy',
                            time: maxDateForPickUp.maxTime | timeFormat,
                          }
                  }}
                </axpo-typography>
              } @else {
                <axpo-typography [variant]="'body-s'">
                  {{ 'order.NoLimit' | transloco }}
                </axpo-typography>
              }
            </div>
          </div>
        }
      } @else {
        <div></div>
      }
      <div>
        <div class="flex items-center">
          <div class="w-6 h-6 m-1 border bg-secondary border-border-soft"></div>
          <div>{{ 'order.unavailable' | transloco }}</div>
        </div>
        <div class="flex items-center">
          <div class="w-6 h-6 m-1 border bg-tertiary border-border-soft"></div>
          <div>{{ 'order.existingBooking' | transloco }}</div>
        </div>
        <div class="flex items-center">
          <div class="w-6 h-6 m-1 border bg-light-yellow border-border-soft"></div>
          <div>{{ 'order.yourSelection' | transloco }}</div>
        </div>
      </div>
    </div>
  </div>
} @else {
  <div class="flex items-center justify-center h-96">
    <axpo-spinner>{{ 'core.Loading' | transloco }}</axpo-spinner>
  </div>
}
