<div class="container px-2 mx-auto">
  <div class="flex mt-24">
    <div class="flex w-1/2">
      <axpo-search
        [placeholder]="'core.enterText' | transloco"
        (debounceValue)="onSearchValueChange($event)"
      ></axpo-search>
    </div>
    <div class="flex justify-end w-1/2 gap-2">
      <axpo-dropdown
        [defaultText]="'customer.AllCustomers' | transloco"
        [showSelectedValue]="true"
        [items]="[
          { value: 'all', label: 'customer.AllCustomers' | transloco },
          { value: 'active', label: 'customer.ActiveCustomers' | transloco },
        ]"
        [type]="'filled'"
        [size]="'medium'"
        (onSelect)="onSelectActiveCustomers($event)"
      />
      <axpo-button (click)="onAddCustomerClick()">
        <i class="pr-1 fas fa-plus"></i>
        {{ 'customer.AddCustomer' | transloco }}
      </axpo-button>
    </div>
  </div>
  <div class="mt-20">
    <axpo-table
      [data]="customerListData()"
      [columns]="columns()"
      [actions]="['view']"
      [headerType]="'ghost'"
      [isLoading]="userCustomerService.isLoading()"
      [textIfEmpty]="'core.NoContentFound' | transloco"
      (onSortClick)="onSortClick($event)"
      (onActionClick)="onActionClick($event)"
    />
    <axpo-tablenavigation
      [(page)]="page"
      [(pageSize)]="pageSize"
      [totalItems]="totalItems()"
      [style]="'default'"
      [itemsPerPage]="'core.itemsPerPage' | transloco"
    />
  </div>
</div>
