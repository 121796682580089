import { DatePipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  output,
  signal,
  untracked,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoSpinnerComponent } from '../../../core/axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { LocalizedDatePipe } from '../../../shared/pipes/localized-date.pipe';
import { TimeFormatPipe } from '../../../shared/pipes/time-format.pipe';
import { datetoStringWithoutZone } from '../../../shared/utils/date';
import { formatMinutes, formatTime } from '../../../shared/utils/formatTime';
import { PlantManagementService } from '../../plant-management/plant-management.service';
import { ICalendar, OrderService } from '../order.service';

interface IMaxDateForPickUp {
  maxDate: string;
  maxTime: string;
}

@Component({
  selector: 'app-collection-time',
  templateUrl: './collection-time.component.html',
  standalone: true,
  imports: [
    AxpoButtonComponent,
    AxpoSpinnerComponent,
    AxpoFormElementComponent,
    DatePipe,
    NgClass,
    TranslocoPipe,
    LocalizedDatePipe,
    AxpoTypographyComponent,
    TimeFormatPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './collection-time.component.css',
})
export class CollectionTimeComponent {
  nextStep = output<void>();
  prevStep = output<void>();
  router = inject(Router);
  toastr = inject(ToastrService);
  translocoService = inject(TranslocoService);
  orderService = inject(OrderService);
  public plantManagementService = inject(PlantManagementService);
  calendar = signal<ICalendar | undefined>(undefined);
  datesOfSelectedWeek = signal<Date[]>([]);
  nextWeekAvailable = signal<boolean>(true);
  previousWeekAvailable = signal<boolean>(false);
  maxDateForPickUp = signal<IMaxDateForPickUp | undefined>(undefined);
  dateForPickUp = signal<string | undefined>(undefined);
  timeForPickUp = signal<string | undefined>(undefined);
  openingHour = signal<string | undefined>(undefined);
  closingHour = signal<string | undefined>(undefined);

  weekdays = Array.from(Array(7).keys());
  hours = Array.from(Array(24).keys());
  hoursString = this.hours.map(i => (i < 10 ? '0' : '') + `${i}:00`);

  constructor() {
    this.datesOfSelectedWeek.set(this.getDatesOfWeek(new Date()));
    this.requestTimeSlot();
  }

  _loadOperatingHoursEffect = effect(() => {
    const plantId = this.orderService.selectedPlantId();
    if (plantId) {
      untracked(() => {
        this.plantManagementService.loadOperatingHours(plantId);
      });
    }
  });

  _getMaxDateForPickUpEffect = effect(() => {
    const selectedTimeSlot = this.orderService.selectedTimeSlot();
    untracked(() => {
      const timeWindows = this.orderService.timeWindows();
      if (selectedTimeSlot) {
        const timeIndex = timeWindows?.timestamps.indexOf(selectedTimeSlot.dropOff);
        const maxDate = timeWindows?.maxPickUpDates.slice(timeIndex).find(item => item !== 'none');
        if (maxDate) {
          this.maxDateForPickUp.set({
            maxDate: maxDate === 'Infinite' ? 'Infinite' : maxDate.split('T')[0],
            maxTime: maxDate === 'Infinite' ? 'Infinite' : maxDate.split('T')[1],
          });
        }
      }
    });
  });

  _setDateForPickUpEffect = effect(() => {
    const selectedTimeSlot = this.orderService.selectedTimeSlot();
    if (selectedTimeSlot) {
      untracked(() => {
        this.dateForPickUp.set(selectedTimeSlot.pickUp.split('T')[0]);
        this.timeForPickUp.set(selectedTimeSlot.pickUp.split('T')[1]);
      });
    }
  });

  _openingHoursEffect = effect(() => {
    const selectedDate = this.dateForPickUp();
    untracked(() => {
      const weekOpeningHours = this.plantManagementService.operatingHours();
      if (selectedDate && weekOpeningHours) {
        const dateObj = new Date(selectedDate);
        const dayOfWeek = dateObj.getDay();
        const dayNames = [
          'sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
        ];

        const dayInfo = weekOpeningHours.find(day => day.day === dayNames[dayOfWeek]);

        if (this.timeForPickUp() && dayInfo) {
          if (this.timeForPickUp()! < dayInfo.startTime) {
            this.timeForPickUp.set(dayInfo.startTime);
          }
          if (this.timeForPickUp()! > dayInfo.endTime) {
            this.timeForPickUp.set(dayInfo.endTime);
          }
        }

        if (dayInfo?.operating && selectedDate === this.maxDateForPickUp()?.maxDate) {
          this.openingHour.set(formatTime(dayInfo.startTime));
          this.closingHour.set(this.maxDateForPickUp()?.maxTime);
        } else if (dayInfo?.operating) {
          this.openingHour.set(formatTime(dayInfo.startTime));
          this.closingHour.set(formatTime(dayInfo.endTime));
        } else {
          this.openingHour.set(undefined);
          this.closingHour.set(undefined);
        }
      }
    });
  });

  _extendTimeSlotEffect = effect(() => {
    const dateForPickUp = this.dateForPickUp();
    const timeForPickUp = this.timeForPickUp();

    if (dateForPickUp && timeForPickUp) {
      untracked(() => {
        const dropOff = this.orderService.selectedTimeSlot()?.dropOff;
        this.orderService.selectedTimeSlot.set({
          dropOff: dropOff!,
          pickUp: `${dateForPickUp}T${formatMinutes(timeForPickUp)}`,
        });
      });
    }
  });

  requestTimeSlot = () => {
    const start = this.datesOfSelectedWeek()[0];
    const end = new Date(this.datesOfSelectedWeek()[0].getTime() + 7 * 24 * 60 * 60 * 1000);
    this.orderService.loadTimeWindows(start, end);
  };

  getDatesOfWeek = (someDayInTheWeek: Date) => {
    const monday = new Date(someDayInTheWeek);
    monday.setDate(someDayInTheWeek.getDate() - ((someDayInTheWeek.getDay() + 6) % 7));
    monday.setHours(0, 0, 0, 0);
    return this.weekdays.map((_day, index) => {
      const date = new Date(monday);
      date.setDate(monday.getDate() + index);
      return date;
    });
  };

  _timeSlotsEffect = effect(() => {
    const timeSlots = this.orderService.timeSlots();
    const selectedTimeSlot = this.orderService.selectedTimeSlot();
    untracked(() => {
      if (!timeSlots) {
        this.calendar.set(undefined);
        return;
      }

      const calendar: ICalendar = {};

      // add booked and blocked hours
      for (let i = 0; i < 7; i++) {
        calendar[i] = {};
        for (let j = 0; j < 24; j++) {
          if (timeSlots[i]?.[j]) {
            calendar[i][j] = timeSlots[i][j];
          } else {
            calendar[i][j] = 'unavailable';
          }
        }
      }

      if (selectedTimeSlot) {
        const hoursInBetween = Math.ceil(
          (new Date(selectedTimeSlot.pickUp).getTime() -
            new Date(selectedTimeSlot.dropOff).getTime()) /
            (1000 * 60 * 60),
        );
        const startDayNum = Math.floor(
          (new Date(selectedTimeSlot.dropOff).getTime() - this.datesOfSelectedWeek()[0].getTime()) /
            (1000 * 60 * 60 * 24),
        );
        const startHour = new Date(selectedTimeSlot.dropOff).getHours();
        for (let i = 0; i < hoursInBetween; i++) {
          const currentHour = (startHour + i) % 24;
          const currentDayNum = startDayNum + Math.floor((startHour + i) / 24);
          if (currentDayNum < 0 || currentDayNum > 6) continue; // element is part of another week
          if (calendar[currentDayNum][currentHour] === 'available') {
            calendar[currentDayNum][currentHour] = 'selected';
          } else {
            calendar[currentDayNum][currentHour] = 'selected_but_unavailable';
          }
        }
      }
      this.calendar.set(calendar);
    });
  });

  changeWeek = (direction: 'next' | 'previous') => {
    const newDate = new Date(this.datesOfSelectedWeek()[0]);
    newDate.setDate(this.datesOfSelectedWeek()[0].getDate() + (direction === 'next' ? 7 : -7));
    this.datesOfSelectedWeek.set(this.getDatesOfWeek(newDate));
    this.requestTimeSlot();
    if (direction === 'next') {
      this.previousWeekAvailable.set(true);
      this.nextWeekAvailable.set(false);
    } else {
      this.previousWeekAvailable.set(false);
      this.nextWeekAvailable.set(true);
    }
  };

  onTimeSelect = async (day: number, hour: number) => {
    if (!['available', 'selected'].includes(this.calendar()?.[day]?.[hour] || '')) return;
    const dropOff = new Date(this.datesOfSelectedWeek()[day].getTime());
    dropOff.setHours(hour);
    const dropOffStr = datetoStringWithoutZone(dropOff);
    const pickupTime = this.orderService.getFillingStartAndPickUpFromDropOff(dropOffStr);
    if (!pickupTime) return;

    this.orderService.selectedTimeSlot.set({
      dropOff: dropOffStr,
      pickUp: pickupTime.pickUp,
    });
  };

  disableContinueButton = () => {
    const selectedTimeSlot = this.orderService.selectedTimeSlot();
    const timeForPickUp = this.timeForPickUp();
    const openingHour = this.openingHour();
    const closingHour = this.closingHour();
    const maxDateForPickUp = this.maxDateForPickUp()?.maxDate;
    const dateForPickUp = new Date(this.dateForPickUp()!);

    if (maxDateForPickUp === 'Infinite' || maxDateForPickUp === 'None') {
      return false;
    }

    const maxDate = new Date(maxDateForPickUp!);
    const isTimeInRange = timeForPickUp! >= openingHour! && timeForPickUp! <= closingHour!;
    const isDateValid = dateForPickUp <= maxDate;

    if (!selectedTimeSlot || !isTimeInRange || !isDateValid) {
      return true;
    }

    return false;
  };

  back = () => {
    this.prevStep.emit();
  };

  continue = () => {
    this.nextStep.emit();
  };
}
