<div class="container mx-auto">
  <div class="mt-20 text-center">
    <axpo-typography [variant]="'H2'">
      {{ 'homepage.Welcome' | transloco }}
    </axpo-typography>
  </div>
  <div class="mb-12 text-center mt-14">
    <axpo-typography [variant]="'title-xl'">
      {{ 'homepage.Title' | transloco }}
    </axpo-typography>
  </div>
  <axpo-icon-gallery [icons]="galleryIcons()" />
</div>
