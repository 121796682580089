<div class="container mx-auto mt-4">
  <div class="flex flex-col items-center">
    <axpo-typography [variant]="'title-xl'" class="my-12">
      {{ 'core.NotFoundTitle' | transloco }}
    </axpo-typography>

    <svg
      width="60"
      height="60"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-current text-primary"
    >
      <g id="shape">
        <path
          id="Vector"
          d="M44.9308 21.1717C44.4592 15.685 42.4154 11.2955 38.7996 7.84671C38.6424 7.68994 38.1708 7.53318 38.0136 7.84671C37.8563 8.00347 37.6991 8.47377 38.0136 8.63053C41.4722 11.9226 43.3587 15.9985 43.8303 21.3285C44.6164 32.7723 36.2843 42.6485 24.6507 43.7459C13.1744 44.6865 3.11292 36.2212 2.16966 24.7773C1.2264 13.3335 9.55854 3.45729 21.1921 2.35994C25.9084 1.88964 29.9958 2.83023 33.7689 5.33847C34.0833 5.49523 34.3977 5.49523 34.5549 5.1817C34.7122 4.86817 34.5549 4.55464 34.3977 4.39788C30.4675 1.73288 26.0656 0.635522 21.1921 1.10582C8.92969 2.35994 0.125932 12.7064 1.06919 24.9341C2.01245 36.3779 11.6023 45 23.0786 45H24.8079C37.0703 43.7459 45.8741 33.3994 44.9308 21.1717Z"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="1.5"
        />
        <path
          id="shape_2"
          d="M21 14.3571C21 14.9823 21.244 15.5818 21.6783 16.0239C22.1126 16.4659 22.7016 16.7143 23.3158 16.7143C23.93 16.7143 24.519 16.4659 24.9533 16.0239C25.3876 15.5818 25.6316 14.9823 25.6316 14.3571C25.6316 13.732 25.3876 13.1324 24.9533 12.6904C24.519 12.2483 23.93 12 23.3158 12C22.7016 12 22.1126 12.2483 21.6783 12.6904C21.244 13.1324 21 13.732 21 14.3571ZM24.4737 19.8571H22.1579C21.9456 19.8571 21.7719 20.0339 21.7719 20.25V33.6071C21.7719 33.8232 21.9456 34 22.1579 34H24.4737C24.686 34 24.8596 33.8232 24.8596 33.6071V20.25C24.8596 20.0339 24.686 19.8571 24.4737 19.8571Z"
          fill="currentColor"
        />
      </g>
    </svg>

    <axpo-button [variant]="'default'" class="mt-12" (click)="navigateToHome()">
      {{ 'core.BackToHomepage' | transloco }}
    </axpo-button>
    <axpo-typography [variant]="'body-xl'" class="my-12">
      {{ 'core.NotFoundText' | transloco }}
    </axpo-typography>
  </div>
</div>
