<div class="container px-2 mx-auto">
  <div class="mt-11">
    <axpo-typography [variant]="'body-l'" [weight]="'bold'">
      {{ 'plantManagement.OpeningHours' | transloco }}
    </axpo-typography>
  </div>
  <div class="mt-2">
    <axpo-table
      [data]="this.operatingHourData()"
      [columns]="columnsOperatingHours()"
      [actions]="['edit']"
      [headerType]="'ghost'"
      [isLoading]="plantManagementService.isLoading()"
      (onActionClick)="onActionOperatingHoursClick($event)"
    />
  </div>
  <div class="mt-20">
    <axpo-typography [variant]="'body-l'" [weight]="'bold'">
      {{ 'plantManagement.FillingBays' | transloco }}
    </axpo-typography>
  </div>
  <div class="mt-2 mb-12">
    <axpo-table
      [data]="this.fillingBayData()"
      [columns]="columnsFillingBays()"
      [actions]="['edit']"
      [headerType]="'ghost'"
      [isLoading]="plantManagementService.isLoading()"
      (onActionClick)="onActionFillingBayClick($event)"
    />
  </div>
</div>

@if (plantManagementService.showPlacedOrderWarning(); as placedOrders) {
  <axpo-dialog
    [title]="
      'plantManagement.PlacedOrdersWarningTitle'
        | transloco: { filling_bay: placedOrders.fillingBayNumber }
    "
    icon="warning"
    [confirmText]="'core.IUnderstand' | transloco"
    (takenAction)="plantManagementService.showPlacedOrderWarning.set(undefined)"
  >
    {{ 'plantManagement.PlacedOrdersWarningText' | transloco }}
    @for (order of placedOrders.orders; track order.id) {
      <div class="mt-2 flex text-left">
        <axpo-typography weight="bold" class="w-32">
          {{ 'orderList.Order' | transloco }} {{ order.orderNumber }}
        </axpo-typography>
        <axpo-typography>
          {{ order.dropOff | date: 'dd.MM.yyyy hh:mm' }} -
          {{ order.pickUp | date: 'dd.MM.yyyy hh:mm' }}
        </axpo-typography>
      </div>
    }
  </axpo-dialog>
}
