import { ChangeDetectionStrategy, Component, input, model, output } from '@angular/core';
import { RouterLink } from '@angular/router';

import { AxpoLogoComponent } from './axpo-logo/axpo-logo.component';
import {
  AxpoBreadcrumbComponent,
  SeparatorType,
} from '../axpo-breadcrumb/axpo-breadcrumb.component';
import {
  AxpoProfileDropdownComponent,
  ICustomAction,
  ILanguage,
  IUser,
} from '../axpo-profile-dropdown/axpo-profile-dropdown.component';
import { AxpoProfileSubmenuComponent } from '../axpo-profile-dropdown/axpo-profile-submenu/axpo-profile-submenu.component';
import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';

@Component({
  selector: 'axpo-header',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AxpoLogoComponent,
    AxpoTypographyComponent,
    RouterLink,
    AxpoBreadcrumbComponent,
    AxpoProfileSubmenuComponent,
    AxpoProfileDropdownComponent,
  ],
  templateUrl: './axpo-header.component.html',
  styleUrl: './axpo-header.component.css',
})
export class AxpoHeaderComponent {
  title = input<string>();
  feedback = input<boolean>(false);
  onFeedbackClick = output<void>();

  separator = input<SeparatorType>('default');

  profileImage = input<string | undefined>(undefined);
  user = input<IUser | undefined>(undefined);
  showLogout = input<boolean>(true);
  availableLanguages = input<ILanguage[]>([]);
  selectedLanguageCode = model<string | undefined>(undefined);
  customActions = input<ICustomAction[]>([]);
  onLogout = output<void>();
  onCustomAction = output<{ value: string; subvalue?: string }>();

  handleFeedbackClick(event: Event) {
    event.stopPropagation();
    this.onFeedbackClick.emit();
  }

  onHeaderLogout = () => {
    this.onLogout.emit();
  };

  onCustomHeaderAction = (value: string, subvalue?: string) => {
    this.onCustomAction.emit({ value, subvalue });
  };
}
