<div class="flex flex-wrap px-8 py-2 justify-center xs:px-2 md:px-12">
  @for (icon of icons(); track icon.text) {
    @if (icon.link && icon.target === 'external_link') {
      <a
        [href]="icon.link"
        target="_blank"
        class="w-full px-8 mb-8 xs:w-1/3 sm:w-1/4 lg:w-1/5 xl:w-1/6 min-h-28"
      >
        <ng-container
          [ngTemplateOutlet]="foobar"
          [ngTemplateOutletContext]="{ $implicit: icon }"
        ></ng-container>
      </a>
    }
    @if (icon.link && icon.target === 'internal_link') {
      <a
        [routerLink]="icon.link"
        class="w-full px-8 mb-8 xs:w-1/3 sm:w-1/4 lg:w-1/5 xl:w-1/6 min-h-28"
      >
        <ng-container
          [ngTemplateOutlet]="foobar"
          [ngTemplateOutletContext]="{ $implicit: icon }"
        ></ng-container>
      </a>
    }
  }
</div>

<ng-template #foobar let-icon>
  <axpo-typography [variant]="'body-l'">
    <div class="flex-col items-center h-full p-1 px-4 text-text-primary hover:text-primary">
      <div class="w-12 h-12 m-2 mx-auto overflow-hidden">
        <img [src]="icon.path" [alt]="icon.alt ? icon.alt : 'image'" class="w-full h-full" />
      </div>
    </div>
    <div class="mt-1 mb-2 text-center">{{ icon.text }}</div>
  </axpo-typography>
</ng-template>
