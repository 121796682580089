import { Injectable, computed, effect, inject, signal, untracked } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import {
  IContract,
  IContractDetails,
  ICustomer,
  IOrder,
  IPage,
  IPlant,
  ITrailer,
} from '../../shared/models/api_models';
import { ApiService } from '../../shared/services/api.service';
import { UserService } from '../../shared/services/user-data.service';
import { datetoStringWithoutZone } from '../../shared/utils/date';

export const BOOKING_OPTIONS = [
  'available',
  'unavailable',
  'booked_by_same_customer',
  'selected',
  'selected_but_unavailable',
] as const;
export type ICalendar = Record<string, Record<string, (typeof BOOKING_OPTIONS)[number]>>;
export interface ITimeSlot {
  dropOff: string;
  pickUp: string;
}

export interface ITimeWindows {
  timestamps: string[];
  type: string[];
  available: boolean[];
  possibleDropOffTimes: string[];
  possiblePickUpTimes: string[];
  maxPickUpDates: string[];
}

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  apiService = inject(ApiService);
  userService = inject(UserService);
  toastr = inject(ToastrService);
  translocoService = inject(TranslocoService);

  orderId = signal<string | undefined>(undefined); // used for editing an existing order

  customers = signal<ICustomer[]>([]);
  selectedCustomerId = signal<string | undefined>(undefined);
  plants = signal<IPlant[]>([]);
  selectedPlantId = signal<string | undefined>(undefined);
  contracts = signal<IContract[]>([]);
  selectedContractId = signal<string | undefined>(undefined);
  contractDetails = signal<IContractDetails | undefined>(undefined);
  connectorTypes = signal<string[]>([]);
  selectedConnectorType = signal<string | undefined>(undefined);
  trailers = signal<ITrailer[]>([]);
  selectedTrailerId = signal<string | undefined>(undefined);
  selectedAmount = signal<number | undefined>(undefined);
  selectedAmountInM3 = computed(() => {
    const inKg = this.selectedAmount();
    if (inKg) return inKg * 11.891;
    return null;
  });
  timeWindows = signal<ITimeWindows | undefined>(undefined);
  timeSlots = signal<ICalendar | undefined>(undefined);
  selectedTimeSlot = signal<ITimeSlot | undefined>(undefined);
  orderDetailsValid = signal<boolean>(false);

  maxAmountPerOrder = computed(() => {
    if (!this.contractDetails() || !this.selectedContractId()) return 0;
    const availableAmount = this.contractDetails()!.availableAmount;
    const maxAmountPerOrder = this.contractDetails()!.maxAmountPerOrder;
    return Math.min(availableAmount, maxAmountPerOrder);
  });

  _selectedPlantEffect = effect(() => {
    const plantId = this.selectedPlantId();
    const customerId = this.selectedCustomerId();
    untracked(() => {
      this.loadContracts(plantId, customerId);
      this.loadConnectorTypes(plantId);
    });
  });

  _selectedContractEffect = effect(() => {
    const contractId = this.selectedContractId();
    untracked(() => {
      this.loadContractDetails(contractId);
    });
  });

  _selectedConnectorTypeEffect = effect(() => {
    const connectorType = this.selectedConnectorType();
    const customerId = this.selectedCustomerId();
    untracked(() => {
      if (connectorType && customerId) {
        this.loadTrailers(connectorType, customerId);
      }
    });
  });

  _validateOrderDetailsEffect = effect(() => {
    const plantId = this.selectedPlantId();
    const contractId = this.selectedContractId();
    const contractDetails = this.contractDetails();
    const connectorType = this.selectedConnectorType();
    const trailerId = this.selectedTrailerId();
    const amount = this.selectedAmount();
    const contract = this.contractDetails();
    untracked(() => {
      if (
        plantId &&
        contractId &&
        contractDetails &&
        connectorType &&
        trailerId &&
        amount &&
        contract
      ) {
        if (amount >= contract.minAmountPerOrder && amount <= this.maxAmountPerOrder()) {
          this.orderDetailsValid.set(true);
          return;
        }
      }
      this.orderDetailsValid.set(false);
    });
  });

  _clearSelectionEffect = effect(() => {
    const _selectedCustomerId = this.selectedCustomerId();
    const plants = this.plants();
    const selectedPlantId = this.selectedPlantId();
    const contracts = this.contracts();
    const selectedContractId = this.selectedContractId();
    const connectorTypes = this.connectorTypes();
    const selectedConnectorType = this.selectedConnectorType();
    const trailers = this.trailers();
    const selectedTrailerId = this.selectedTrailerId();

    untracked(() => {
      this.selectedTimeSlot.set(undefined);
      if (selectedPlantId && !plants.map(plant => plant.id).includes(selectedPlantId)) {
        this.selectedPlantId.set(undefined);
        this.trailers.set([]);
      }
      if (
        selectedContractId &&
        !contracts.map(contract => contract.id).includes(selectedContractId)
      ) {
        this.selectedContractId.set(undefined);
      }
      if (selectedConnectorType && !connectorTypes.includes(selectedConnectorType)) {
        this.selectedConnectorType.set(undefined);
      }
      if (
        (selectedTrailerId && !trailers.map(trailer => trailer.id).includes(selectedTrailerId)) ||
        (!selectedConnectorType && selectedTrailerId)
      ) {
        this.selectedTrailerId.set(undefined);
      }
    });
  });

  private calculateTimeSlots = (start: Date, timeWindows: ITimeWindows) => {
    const timeSlots = this.timeSlots() || {};
    for (let i = 0; i < timeWindows.timestamps.length; i++) {
      const bookingType = timeWindows.available[i]
        ? 'available'
        : timeWindows.type[i] == 'booking'
          ? 'booked_by_same_customer'
          : 'unavailable';
      let timestamp;
      if (bookingType == 'available') {
        timestamp = new Date(timeWindows.possibleDropOffTimes[i]);
      } else {
        timestamp = new Date(timeWindows.timestamps[i]);
      }
      const dayNum = Math.floor(
        (new Date(timestamp).getTime() - start.getTime()) / (1000 * 60 * 60 * 24),
      );
      const hour = timestamp.getHours();
      if (!timeSlots[dayNum]) timeSlots[dayNum] = {};
      timeSlots[dayNum][hour] = bookingType;
    }
    this.timeSlots.set(timeSlots);
  };

  public getFillingStartAndPickUpFromDropOff = (
    dropOff: string,
  ): { pickUp: string } | undefined => {
    const timeWindows = this.timeWindows()!;
    // find all indexes of dropOff in possibleDropOffTimes
    const indexes = timeWindows.possibleDropOffTimes.reduce((acc, time, i) => {
      if (time == dropOff) acc.push(i);
      return acc;
    }, [] as number[]);
    // find the index of the first possible dropOff time that is available
    const idx = indexes.find(i => timeWindows.available[i]);
    if (idx === undefined) return undefined;
    return {
      pickUp: timeWindows.possiblePickUpTimes[idx],
    };
  };

  public resetSelections = () => {
    this.selectedCustomerId.set(undefined);
    this.selectedPlantId.set(undefined);
    this.selectedContractId.set(undefined);
    this.contractDetails.set(undefined);
    this.selectedConnectorType.set(undefined);
    this.selectedTrailerId.set(undefined);
    this.selectedAmount.set(undefined);
    this.selectedTimeSlot.set(undefined);
    this.orderId.set(undefined);
  };

  public loadPlants = () => {
    this.apiService.get<IPage<IPlant>>('api/v1/plant').subscribe(plants => {
      this.plants.set(plants.data);
    });
  };

  public loadCustomers = () => {
    this.apiService.get<IPage<ICustomer>>(`api/v1/company?onlyActive=true`).subscribe({
      next: response => {
        this.customers.set(response.data.filter(company => !company.isOperator));
      },
      error: () => {
        this.toastr.error(this.translocoService.translate('customer.FailedToFetchCustomer'));
      },
    });
  };

  /* contracts and connectorTypes are loaded based on the selected plant */

  private loadContracts = (plantId?: string, customerId?: string) => {
    this.contracts.set([]);
    if (plantId && customerId) {
      this.apiService
        .get<IPage<IContract>>(`api/v1/contract?plantId=${plantId}&companyId=${customerId}`)
        .subscribe(contracts => {
          this.contracts.set(contracts.data);
        });
    }
  };

  private loadConnectorTypes = (plantId?: string) => {
    this.connectorTypes.set([]);
    if (plantId) {
      this.apiService
        .get<string[]>(`api/v1/filling_bay/types/?plantKks=${plantId}`)
        .subscribe(connectorTypes => {
          this.connectorTypes.set(connectorTypes);
        });
    }
  };

  /* trailers are loaded based on the selected connectorType */

  private loadTrailers = (connectorType: string, customerId: string) => {
    this.trailers.set([]);
    this.apiService
      .get<IPage<ITrailer>>(`api/v1/trailer?connectorType=${connectorType}&companyId=${customerId}`)
      .subscribe(trailers => {
        this.trailers.set(trailers.data);
      });
  };

  /* contract details are loaded based on the selected contract */

  private loadContractDetails = (contractId?: string) => {
    if (!contractId) {
      this.contractDetails.set(undefined);
    } else {
      this.apiService.get<IContractDetails>(`api/v1/contract/${contractId}`).subscribe(contract => {
        this.contractDetails.set(contract);
      });
    }
  };

  /* the time windows are loaded based on the selected plant and connector type */
  public loadTimeWindows = (start: Date, end: Date) => {
    this.timeSlots.set(undefined);
    const plantId = this.selectedPlantId();
    const connectorType = this.selectedConnectorType();
    const selectedAmount = this.selectedAmount();
    const selectedContractId = this.selectedContractId();
    if (!plantId || !connectorType || !selectedAmount || !selectedContractId) {
      const msg = this.translocoService.translate('order.ParameterFromPreviousPageMissing');
      this.toastr.error(msg);
      this.timeSlots.set(undefined);
      return;
    }
    const query: Record<string, string | number> = {
      plantId: plantId,
      orderAmount: selectedAmount,
      startDate: datetoStringWithoutZone(start),
      endDate: datetoStringWithoutZone(end),
      connectorType: connectorType,
      contractId: selectedContractId,
    };
    if (this.orderId()) query.orderIdToExclude = this.orderId()!;

    this.apiService.get<ITimeWindows>(`api/v1/plant/time_windows`, { params: query }).subscribe({
      next: timeWindows => {
        this.timeWindows.set(timeWindows);
        this.calculateTimeSlots(start, timeWindows);
      },
      error: () => {
        const msg = this.translocoService.translate('order.CouldNotLoadTimeSlots');
        this.toastr.error(msg);
      },
    });
  };

  public submitOrder = (): Observable<void> => {
    if (this.orderId()) {
      // edit order
      return this.apiService.patch<void>(`api/v1/order/${this.orderId()}`, {
        body: {
          contractId: this.selectedContractId()!,
          trailerId: this.selectedTrailerId()!,
          amount: this.selectedAmount()!,
          dropOff: this.selectedTimeSlot()!.dropOff,
          pickUp: this.selectedTimeSlot()!.pickUp,
          connectorType: this.selectedConnectorType()!,
          plantId: this.selectedPlantId()!,
        },
      });
    } else {
      // create new order
      return this.apiService.post<void>('api/v1/order', {
        body: {
          contractId: this.selectedContractId()!,
          trailerId: this.selectedTrailerId()!,
          amount: this.selectedAmount()!,
          dropOff: this.selectedTimeSlot()!.dropOff,
          pickUp: this.selectedTimeSlot()!.pickUp,
          connectorType: this.selectedConnectorType()!,
          plantId: this.selectedPlantId()!,
        },
      });
    }
  };

  public changeTrailer = () => {
    return this.apiService.patch<void>(`api/v1/order/${this.orderId()}`, {
      body: {
        trailerId: this.selectedTrailerId(),
      },
    });
  };

  public loadOrder = (orderId: string): Observable<IOrder> => {
    return this.apiService.get<IOrder>(`api/v1/order/${orderId}`);
  };
}
