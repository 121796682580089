import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import {
  IFillingBay,
  IFillingBayWithOrders,
  IMaintenanceWindow,
  IOperatingHours,
  IOrder,
  IPage,
  IPlant,
} from '../../shared/models/api_models';
import { ApiService } from '../../shared/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class PlantManagementService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  private router = inject(Router);

  isLoading = signal<boolean>(false);
  plants = signal<IPage<IPlant>>({ count: 0, data: [] });
  operatingHours = signal<IOperatingHours[]>([]);
  fillingBays = signal<IFillingBay[]>([]);
  openingHourDay = signal<IOperatingHours>({} as IOperatingHours);
  fillingBay = signal<IFillingBay>({} as IFillingBay);
  maintenanceWindows = signal<IMaintenanceWindow[]>([]);
  showPlacedOrderWarning = signal<{ fillingBayNumber: string; orders: IOrder[] } | undefined>(
    undefined,
  );

  public loadPlants = (page: number, pageSize: number) => {
    if (this.plants().count == 0) this.isLoading.set(true);
    this.apiService
      .get<IPage<IPlant>>(`api/v1/plant?currentPage=${page}&pageSize=${pageSize}`)
      .subscribe({
        next: response => {
          this.plants.set(response);
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('plantManagement.FailedToFetchPlants'));
        },
        complete: () => {
          this.isLoading.set(false);
        },
      });
  };

  public loadOperatingHours = (plantId: string) => {
    if (this.operatingHours().length === 0) this.isLoading.set(true);
    this.apiService.get<IOperatingHours[]>(`api/v1/week_schedule/?plantKks=${plantId}`).subscribe({
      next: response => {
        this.operatingHours.set(response);
      },
      error: () => {
        this.toastr.error(
          this.translocoService.translate('plantManagement.FailedToFetchOperatingHours'),
        );
      },
      complete: () => {
        this.isLoading.set(false);
      },
    });
  };

  public loadFillingBays = (plantId: string) => {
    if (this.fillingBays().length == 0) this.isLoading.set(true);
    this.apiService.get<IFillingBay[]>(`api/v1/filling_bay/?plantKks=${plantId}`).subscribe({
      next: response => {
        this.fillingBays.set(response);
      },
      error: () => {
        this.toastr.error(
          this.translocoService.translate('plantManagement.FailedToFetchFillingBays'),
        );
      },
      complete: () => {
        this.isLoading.set(false);
      },
    });
  };

  public loadMaintenanceWindows = (plantId: string) => {
    this.apiService.get<IMaintenanceWindow[]>(`api/v1/blocker?plantKks=${plantId}`).subscribe({
      next: response => {
        this.maintenanceWindows.set(response.sort((a, b) => (a.start < b.start ? -1 : 1)));
      },
      error: () => {
        this.toastr.error(
          this.translocoService.translate('plantManagement.FailedToFetchMaintenanceWindows'),
        );
      },
    });
  };

  public loadOpeningHourDay = (dayId: string) => {
    if (Object.keys(this.openingHourDay()).length === 0) this.isLoading.set(true);
    this.apiService.get<IOperatingHours>(`api/v1/week_schedule/${dayId}`).subscribe({
      next: response => {
        this.openingHourDay.set(response);
      },
      error: () => {
        this.toastr.error(
          this.translocoService.translate('plantManagement.FailedToFetchOperatingHours'),
        );
      },
      complete: () => {
        this.isLoading.set(false);
      },
    });
  };

  public updateOpeningHourDay = (
    dayId: string,
    startTime: string,
    endTime: string,
    operating: boolean,
    plantId: string,
  ) => {
    this.apiService
      .patch(`api/v1/week_schedule/${dayId}`, {
        body: {
          startTime: startTime,
          endTime: endTime,
          operating: operating,
        },
      })
      .subscribe({
        next: () => {
          this.toastr.success(this.translocoService.translate('plantManagement.HoursEdited'));
          this.router.navigate(['/plant-management', plantId]);
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('plantManagement.FailedToEditHours'));
        },
      });
  };

  public loadFillingBay = (fillingBayId: string) => {
    if (Object.keys(this.fillingBay()).length === 0) this.isLoading.set(true);
    this.apiService.get<IFillingBay>(`api/v1/filling_bay/${fillingBayId}`).subscribe({
      next: response => {
        this.fillingBay.set(response);
      },
      error: () => {
        this.toastr.error(
          this.translocoService.translate('plantManagement.FailedToFetchFillingBays'),
        );
      },
      complete: () => {
        this.isLoading.set(false);
      },
    });
  };

  public updateFillingBay = (
    fillingBayId: string,
    isOperating: boolean,
    fillingBayNumber: string,
    plantId: string,
  ) => {
    this.apiService
      .patch<IFillingBayWithOrders>(`api/v1/filling_bay/${fillingBayId}`, {
        body: {
          isActive: isOperating,
          number: fillingBayNumber,
        },
      })
      .subscribe({
        next: resp => {
          this.toastr.success(this.translocoService.translate('plantManagement.FillingBayEdited'));
          if (resp.orders.length > 0 && isOperating === false) {
            this.showPlacedOrderWarning.set({ fillingBayNumber, orders: resp.orders });
          }
          this.router.navigate(['/plant-management', plantId]);
        },
        error: (error: HttpErrorResponse) => {
          console.log(error.error.detail.message);
          if (error.error.detail.message === 'Connector number already exists') {
            this.toastr.error(
              this.translocoService.translate('plantManagement.FailedToEditFillingBayDuplicated'),
            );
          } else {
            this.toastr.error(
              this.translocoService.translate('plantManagement.FailedToEditFillingBay'),
            );
          }
        },
      });
  };

  public createMaintenanceWindow = (start: string, end: string, fillingBayInsightsId: string) => {
    this.apiService
      .post<IMaintenanceWindow>('api/v1/blocker', {
        body: {
          start: start,
          end: end,
          fillingBayInsightsId: fillingBayInsightsId,
        },
      })
      .subscribe({
        next: resp => {
          this.toastr.success(
            this.translocoService.translate('plantManagement.MaintenanceWindowAdded'),
          );
          this.maintenanceWindows.update(old => [...old, resp]);
        },
        error: () => {
          this.toastr.error(
            this.translocoService.translate('plantManagement.FailedToAddMaintenanceWindow'),
          );
        },
      });
  };

  public updateMaintenanceWindow = (start: string, end: string, id: string) => {
    this.apiService
      .patch<IMaintenanceWindow>(`api/v1/blocker/${id}`, {
        body: {
          start: start,
          end: end,
        },
      })
      .subscribe({
        next: resp => {
          this.toastr.success(
            this.translocoService.translate('plantManagement.MaintenanceWindowEdited'),
          );
          this.maintenanceWindows.update(old =>
            old.map(window => (window.id === id ? resp : window)),
          );
        },
        error: () => {
          this.toastr.error(
            this.translocoService.translate('plantManagement.FailedToEditMaintenanceWindow'),
          );
        },
      });
  };

  public deleteMaintenanceWindow = (id: string) => {
    this.apiService.delete(`api/v1/blocker/${id}`).subscribe({
      next: () => {
        this.toastr.success(
          this.translocoService.translate('plantManagement.MaintenanceWindowDeleted'),
        );
        this.maintenanceWindows.update(old => old.filter(window => window.id !== id));
      },
      error: () => {
        this.toastr.error(
          this.translocoService.translate('plantManagement.FailedToDeleteMaintenanceWindow'),
        );
      },
    });
  };
}
